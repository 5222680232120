import * as React from "react";
import { ApiCall } from "../../middleware/api";

import { LoadMoreButton } from "../../components/Button";
import { SectionTitle } from "../../styles/section";
import { EventSection, LoadMoreContainer } from "./styles";
import { SkeletonTransition } from "../../components/Skeleton/skeletonTransition";
import EventSkeleton from "./EventSkeleton";
import EventListComponent from "./EventListComponent";
import { FormattedMessage } from "react-intl";

const EventListModule: React.FunctionComponent = () => {
  const [loading, setLoading] = React.useState(false);
  const [offset, setOffset] = React.useState(0);
  const [events, updateEvents] = React.useState([]);
  const [allLoaded, setAllLoaded] = React.useState(false);
  const [error, setError] = React.useState(false);
  const emptyEvents = events.length === 0;

  React.useEffect(() => {
    setLoading(true);
    let promise = ApiCall(`/api/public/v3.0/featured/events?limit=9&offset=${offset}`);
    Promise.resolve(promise)
      .then((res) => {
        updateEvents(events.concat(res["events"]));
        setAllLoaded([...events, ...res["events"]].length >= res["total_count"]);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  return (
    <React.Fragment>
      {!error && (
        <div className={EventSection}>
          <h2 className={SectionTitle}>
            <FormattedMessage
              id="containers.EventList.highlighted"
              defaultMessage="Ting som skjer"
            />
          </h2>
          <SkeletonTransition
            ready={!loading || !emptyEvents}
            placeholder={<EventSkeleton arrSize={9} />}
          >
            <EventListComponent events={events} />
          </SkeletonTransition>
          {!allLoaded && (
            <SkeletonTransition
              ready={!loading || emptyEvents}
              placeholder={<EventSkeleton arrSize={3} />}
            >
              <div className={LoadMoreContainer}>
                <LoadMoreButton updateEvents={() => setOffset(offset + 9)} />
              </div>
            </SkeletonTransition>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default EventListModule;
