import React from "react";
import { FormattedMessage } from "react-intl";
import SadNacho from "../../assets/partials/icon_sad_nacho";
import * as styles from "./styles";

const ErrorPage: React.FC = () => {
    return (
        <div className={styles.errorCard}>
            <SadNacho />
            <FormattedMessage
                id={"containers.ErrorPage.errorText"}
                defaultMessage={
                    "We are currently experiencing some issues with our ticket sale. Please try again later."
                }
            />
        </div>
    );
};

export default ErrorPage;