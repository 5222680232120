import * as React from "react";

import { Organizers } from "./styles";
import { OrganizerProps } from "../../helpers/props";
import OrganizerModule from "./OrganizerModule";

export const OrganizerList: React.FunctionComponent<OrganizerProps> = (props) => {
  return (
    <React.Fragment>
      <div className={Organizers}>
        {props.organizations.map((it) => (
          <OrganizerModule key={it.organization.organization_id} {...it} />
        ))}
      </div>
    </React.Fragment>
  );
};
