import * as React from "react";
import { Hero, HeroInner } from "./styles";
import SearchModule from "../SearchModule";
import HeroText from "./HeroText";

const HeroModule: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <div className={Hero}>
        <div className={HeroInner}>
          <HeroText />
          <SearchModule />
        </div>
      </div>
    </React.Fragment>
  );
};

export default HeroModule;
