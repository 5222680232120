import "whatwg-fetch";

const standardOptions = {
  headers: {
    "Content-Type": "application/json",
    "Accept-Encoding": "br, gzip, compress"
  },
  credentials: "same-origin" as RequestCredentials
};

export function ApiCall(endpoint: string, options = { method: "GET" }) {
  return fetch(endpoint, Object.assign({}, standardOptions, options))
    .then(response => response.json().then(json => ({ json, response })))
    .then(({ json, response }) => {
      if (!response.ok) {
        return Promise.reject(
          Object.assign(
            {},
            {
              response: {
                status: response.status,
                statusText: response.statusText
              }
            },
            json
          )
        );
      }

      return json;
    });
}
