import React from "react";
import { createIntl, createIntlCache, RawIntlProvider } from "react-intl";
import Header from "../containers/Header";
import OrganizerListModule from "../containers/OrganizerList";
import EventListModule from "../containers/EventList";
import Footer from "../containers/Footer";
import { Body } from "../styles/body";
import PromoModule from "../containers/Promo";
import PromoMessageContainer from "../containers/PromoMessage";
import HeroModule from "../containers/HeroModule";
import * as storage from "../helpers/storage";
import messages_en from "../translations/en-US.json";
import messages_no from "../translations/nb-NO.json";

let i18nConfig = {
  locale: storage.getItem("lang", "nb-NO") || "nb-NO",
  messages: storage.getItem("lang", "nb-NO") === "en-US" ? messages_en : messages_no,
};

function onChangeLanguage(lang: string) {
  storage.setItem("lang", lang);
  switch (lang) {
    case "nb-NO":
      i18nConfig.messages = messages_no;
      break;
    case "en-US":
      i18nConfig.messages = messages_en;
      break;
    default:
      i18nConfig.messages = messages_en;
      break;
  }
  i18nConfig.locale = lang;
}

function App() {
  const [lang, setLang] = React.useState<string>(storage.getItem("lang", i18nConfig.locale)); // keeps state of lang in App
  function onChangeLang(lang: string) {
    onChangeLanguage(lang);
    setLang(lang);
  }
  const cache = createIntlCache();

  const intl = createIntl(
    {
      locale: i18nConfig.locale,
      messages: i18nConfig.messages,
      defaultLocale: "nb-NO",
    },
    cache,
  );

  return (
    <div className={Body}>
      <RawIntlProvider value={intl}>
        <PromoMessageContainer />
        <Header onChangeLanguage={onChangeLang} currentLanguage={lang} />
        <HeroModule />
        <OrganizerListModule />
        <EventListModule />
        <PromoModule />
        <Footer />
      </RawIntlProvider>
    </div>
  );
}

export default App;
