import * as React from "react";
import EventModule from "./EventModule";
import { EventListProps } from "../../helpers/props";
import { Events } from "./styles";

const EventListComponent: React.FunctionComponent<EventListProps> = props => {
  //const [filter, setFilter] = React.useState("");

  return (
    <React.Fragment>
      {/*TODO: implement and improve filters when dataset supports it
      <FilterButtons list={props.events} filterBy={["event", "data", "category"]} onClick={(e)=>setFilter(e)} selected={filter}/>*/}
      <div className={Events}>
        {props.events
          /*.filter(it => filter === "" || it.event.data.category === filter)*/
          .map(it => (
            <EventModule
              key={it.event.event_id}
              {...it}
              eventmod3={props.events.length % 3 === 0}
            />
          ))}
      </div>
    </React.Fragment>
  );
};

export default EventListComponent;
