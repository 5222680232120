import { extend, media, style } from "typestyle";
import { Section } from "../../styles/section";
import sizes from "../../constants/sizes";
import colors from "../../constants/colors";
import breakpoints from "../../constants/breakpoints";

export const OrganizerSection = style(
  extend(Section, {
    marginTop: "-10rem",
    paddingBottom: "40px",
    paddingRight: sizes.contentPadding,
    paddingLeft: sizes.contentPadding,
    backgroundColor: colors.hooplaWhite,
  }),
  media(
    { maxWidth: breakpoints.breakpointMedium },
    {
      marginTop: "0",
      paddingTop: "80px",
      paddingBottom: "40px",
      paddingLeft: sizes.contentPaddingSmall,
      paddingRight: sizes.contentPaddingSmall,
    },
  ),
);

export const OrganizerSectionInner = style(
  {},
  media(
    { maxWidth: breakpoints.breakpointDesktop },
    {
      maxWidth: "600px",
      margin: "0 auto",
    },
  ),
);

export const Organizers = style(
  {
    display: "flex",
    margin: "0 auto",
    marginBottom: "70px",
    maxWidth: "1400px",
  },
  media({ maxWidth: breakpoints.breakpointDesktop }, { flexWrap: "wrap" }),
);

export const Organizer = style(
  {
    position: "relative",
    width: "calc(100%/4 - 23px)",
    cursor: "pointer",
    textDecoration: "none",
    display: "block",
    color: colors.hooplaTextColor,
    $nest: {
      "&:hover": {
        $nest: {
          "&>div>span": {
            overflow: "visible",
            textOverflow: "initial",
            transitionTimingFunction: "ease-in",
            transitionDelay: "0.5s",
            maxWidth: "none",
            transition: "2s",
            /* Looks complicated, but relatively simple formula to get the width of the span: 
            - select minimum of view-width - (pixels in margins and padding, not quite sure about the relationship here) and max-width for organizer-element
            - divide by number of cards in width, subtract the same margin as width above (23px)
            - Subtract the width of the span-element (100%)
            - If the translation is positive, i.e. span is shorter than width of the card, do not translate
            */
            transform: "translateX(min(min(100vw - 113px, 1400px) / 4 - 23px - 100%, 0px))",
          },
        },
      },
      "&:focus": {
        $nest: {
          "&>div>span": {
            overflow: "visible",
            textOverflow: "initial",
            transitionTimingFunction: "ease-in",
            maxWidth: "none",
            transition: "2s",
            transitionDelay: "0.5s",
            transform: "translateX(min(min(100vw - 113px, 1400px) / 4 - 23px - 100%, 0px))",
          },
        },
      },
    },
  },
  media(
    { minWidth: breakpoints.breakpointDesktop + 1 },
    {
      $nest: {
        "&:not(:last-child)": {
          marginRight: "30px",
        },
      },
    },
  ),
  media(
    { maxWidth: breakpoints.breakpointDesktop },
    {
      width: "calc(100%/2 - 20px)",
      marginBottom: "50px",
      $nest: {
        "&:nth-child(2n-1)": {
          marginRight: "40px",
        },
        "&:nth-last-child(-n+2)": {
          marginBottom: 0,
        },
        "&>div>span": {
          overflow: "visible",
          whiteSpace: "break-spaces",
        },
      },
    },
  ),
  media(
    { maxWidth: breakpoints.breakpointMedium },
    {
      width: "calc(100%/2 - 10px)",
      $nest: {
        "&:nth-child(2n-1)": {
          marginRight: "20px",
        },
        "&>div>span": {
          overflow: "visible",
          whiteSpace: "break-spaces",
        },
      },
    },
  ),
  media(
    { maxWidth: 330 },
    {
      flex: "0 0 calc(100%/2 - 10px)",
      $nest: {
        "&:nth-child(2n-1)": {
          marginRight: "20px",
        },
        "&>div>span": {
          overflow: "visible",
          whiteSpace: "break-spaces",
        },
      },
    },
  ),
);

export const OrganizerImage = style({
  marginBottom: "18px",
});

export const OrganizerImageImg = style({
  borderRadius: "8px",
  display: "block",
  width: "100%",
});

export const OrganizerTitleAndDetails = style(
  {
    overflow: "hidden",
    // Required to get title to translate
    position: "relative",
    $nest: {
      "&::before": {
        content: `''`,
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        width: "4px",
      },
    },
  },
  media(
    { maxWidth: breakpoints.breakpointMedium },
    {
      position: "relative",
      left: 0,
      bottom: 0,
      width: "100%",
      background: "none",
      padding: 0,
      boxShadow: "none",
    },
  ),
);

export const OrganizerTitle = style(
  {
    overflow: "hidden",
    whiteSpace: "nowrap",
    // Absolute position required to make span scroll
    position: "absolute",
    display: "inline-block",
    maxWidth: "100%",
    textOverflow: "ellipsis",
    fontSize: "23px",
    fontWeight: 900,
    marginBottom: "6px",
  },
  media(
    { maxWidth: breakpoints.breakpointDesktop },
    {
      position: "relative",
      fontSize: "20px",
    },
  ),
  media(
    { maxWidth: breakpoints.breakpointMedium },
    {
      position: "relative",
      fontSize: "18px",
    },
  ),
);

export const OrganizerDetails = style(
  {
    display: "inline-block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginTop: "35px",
    fontSize: "16px",
    opacity: 0.65,
  },
  media(
    { maxWidth: breakpoints.breakpointDesktop },
    {
      whiteSpace: "nowrap",
      flexDirection: "column",
      overflow: "hidden",
      fontSize: "14px",
      marginTop: "0",
    },
  ),
  media(
    { maxWidth: breakpoints.breakpointMedium },
    {
      whiteSpace: "break-spaces",
      flexDirection: "column",
      overflow: "visible",
      fontSize: "14px",
      marginTop: "0",
    },
  ),
);
