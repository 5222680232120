import * as React from "react";
import { Icon } from "./styles";

const IconUser: React.FunctionComponent = () => (
  <React.Fragment>
    <div className={Icon}>
      <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          opacity="0.2"
          d="M8.00055 8.59436C8.7059 8.59436 9.39542 8.38519 9.9819 7.99332C10.5684 7.60144 11.0255 7.04446 11.2954 6.39279C11.5653 5.74113 11.636 5.02406 11.4984 4.33226C11.3608 3.64045 11.0211 3.00499 10.5223 2.50623C10.0236 2.00747 9.38811 1.66781 8.6963 1.5302C8.0045 1.39259 7.28743 1.46322 6.63576 1.73314C5.9841 2.00307 5.42711 2.46018 5.03524 3.04666C4.64336 3.63314 4.4342 4.32266 4.4342 5.02801C4.4342 5.97387 4.80994 6.88098 5.47876 7.5498C6.14758 8.21862 7.05469 8.59436 8.00055 8.59436ZM8.00055 9.78314C4.7314 9.78314 2.05664 11.388 2.05664 13.3495V14.5383H13.9444V13.3495C13.9444 11.388 11.2697 9.78314 8.00055 9.78314Z"
          fill="#191919"
        />
      </svg>
    </div>
  </React.Fragment>
);

export default IconUser;
