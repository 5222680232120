import * as React from "react";
import { AppHeaderNavigationItem, AppHeaderButtonText } from "./styles";
import IconKey from "../../assets/partials/icon_key";
import { FormattedMessage } from "react-intl";

export const OrganizerButton: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <a href={`https://users.${window.ENVIRONMENT.domain}/login/`} className={AppHeaderNavigationItem}>
        <div className={AppHeaderButtonText} style={{ paddingRight: "5px" }}>
          <IconKey />
        </div>
        <span className={AppHeaderButtonText}><FormattedMessage id="containers.Header.organizer" defaultMessage="Arrangører?" />  </span>
      </a>
    </React.Fragment>
  );
};
