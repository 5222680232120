import * as React from "react";
import { ButtonBlue } from "./styles";
import { LoadMoreProps } from "../../helpers/props";
import { FormattedMessage } from "react-intl";

export const LoadMoreButton: React.FunctionComponent<LoadMoreProps> = props => {
  return (
    <React.Fragment>
      <button className={ButtonBlue} onClick={() => props.updateEvents()}>
        <FormattedMessage id="components.LoadMore.showMore" defaultMessage="Last flere" />
      </button>
    </React.Fragment>
  );
};
