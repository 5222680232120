import { cssRule, cssRaw } from "typestyle";
import "./font";
import colors from "../constants/colors";

export const injectGlobals = () => {
  cssRule("*", {
    boxSizing: "border-box",
    fontFamily: "Inter, sans-serif",
    $nest: {
      "@supports (font-variation-settings: normal)": {
        fontFamily: '"Inter V", sans-serif',
      },
    },
  });

  cssRule("input", {
    fontFamily: "Inter, sans-serif",
    $nest: {
      "@supports (font-variation-settings: normal)": {
        fontFamily: '"Inter V", sans-serif',
      },
    },
  });

  cssRule("html, body", {
    height: "100%",
    backgroundColor: colors.hooplaBackground,
    color: colors.hooplaTextColor,
    lineHeight: "1.4rem",
  });

  cssRule("body", {
    display: "flex",
    flexDirection: "column",
  });

  cssRule("#root", {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  });

  cssRaw("* { -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }");
};

export const unit = (n: number): string => `${n * 4}px`;