import * as React from "react";
import { Icon } from "./styles";

const IconVenue: React.FunctionComponent = () => (
  <React.Fragment>
    <div className={Icon}>
      <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.59655 11.3283C7.64981 11.383 7.71318 11.4264 7.78299 11.4561C7.8528 11.4857 7.92769 11.501 8.00332 11.501C8.07895 11.501 8.15383 11.4857 8.22364 11.4561C8.29346 11.4264 8.35682 11.383 8.41008 11.3283L10.7533 8.93415C11.2975 8.37973 11.6682 7.67326 11.8185 6.90409C11.9687 6.13492 11.8918 5.3376 11.5974 4.61299C11.3031 3.88838 10.8045 3.26903 10.1647 2.83327C9.52496 2.39751 8.77278 2.16492 8.00332 2.16492C7.23385 2.16492 6.48168 2.39751 5.84192 2.83327C5.20216 3.26903 4.70357 3.88838 4.4092 4.61299C4.11483 5.3376 4.03792 6.13492 4.18818 6.90409C4.33844 7.67326 4.70913 8.37973 5.25337 8.93415L7.59655 11.3283Z"
          fill="#C7C7C7"
        />
        <circle cx="8.00477" cy="6.05506" r="1.16688" fill="white" />
        <path
          d="M3.69494 12.276L2.89985 13.639C2.74857 13.8984 2.93563 14.224 3.23586 14.224H12.7734C13.0737 14.224 13.2607 13.8984 13.1094 13.639L12.3143 12.276C12.1749 12.037 11.919 11.89 11.6423 11.89H9.79024C9.57406 11.89 9.36763 11.98 9.22044 12.1383L8.57868 12.8286C8.26938 13.1613 7.74202 13.1593 7.43518 12.8244L6.8106 12.1425C6.66323 11.9816 6.45507 11.89 6.2369 11.89H4.36697C4.09026 11.89 3.83437 12.037 3.69494 12.276Z"
          fill="#C7C7C7"
        />
      </svg>
    </div>
  </React.Fragment>
);

export default IconVenue;
