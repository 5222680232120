import { style } from "typestyle";
import colors from "../../constants/colors";

export const PromoMessage = style({
  display: "flex",
  justifyContent: "center",
  textAlign: "center" as "center",
  background: colors.hooplaPurple,
  padding: "16px 4px",
  textDecoration: "none",
  $nest: {
    "&>div": {
      fontSize: "15px",
      color: colors.hooplaTextColor,
      $nest: {
        "&>strong": {
          fontWeight: 700
        }
      }
    }
  }
});

export const PromoMessageInline = style({
  margin: "2px 10px 0"
})