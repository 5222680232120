import * as React from "react";
import { EventDetail, EventIcon, EventDate } from "./styles";
import IconDate from "../../assets/partials/icon_date";
import IconVenue from "../../assets/partials/icon_venue";
import { EventDetailsProps } from "../../helpers/props";
import FormattedDateHelper from "../../helpers/FormattedDateHelper";
import IconTag from "../../assets/partials/icon_tag";
import categoryHelper, { CategoryName, isOfType } from "../../helpers/CategoryHelper";
import { useIntl } from "react-intl";

const formatLocation = (name: string | null, postal_code: string | null, postal_area: string | null) => {
  if (!name && !postal_code && !postal_area) {
    return null
  } else if (!name) {
    return <p>{postal_code} {postal_area}</p>
  } else if (!postal_code && !postal_area) {
    return <p>{name}</p>
  } else {
    return <p>{name}, {postal_code} {postal_area}</p>
  }
}

const EventDetails: React.FunctionComponent<EventDetailsProps> = (props) => {
  const intl = useIntl();
  return (
    <React.Fragment>
      {props.dateFormat?.date_type !== "dont_show" && 
      <div className={EventDetail}>
        <div className={EventIcon}>
          <IconDate />
        </div>
        <div className={EventDate}>
          <FormattedDateHelper
            start={props.start}
            end={props.end}
            dateFormat={props.dateFormat?.date_type}
            text={props.dateFormat?.date_text}
          ></FormattedDateHelper>
        </div>
      </div>}
      {props.location && (props.location.name || props.location.postal_area || props.location.postal_code) && (
        <div className={EventDetail}>
          <div className={EventIcon}>
            <IconVenue />
          </div>
          {formatLocation(props.location.name, props.location.postal_code, props.location.postal_area)}
        </div>
      )}
      <div className={EventDetail}>
        <div className={EventIcon}>
          <IconTag />
        </div>
        <p>{isOfType(props.category) ? intl.formatMessage(categoryHelper(props.category as CategoryName)) : props.category}</p>
      </div>
    </React.Fragment>
  );
};

export default EventDetails;
