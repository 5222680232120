import { keyframes, style } from "typestyle";

export const placeholderAnimation = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0, transition: "opacity 500ms ease-in" },
  unmounted: { display: "none" }
};

export const childAnimation = {
  entering: { opacity: 0, height: 0 },
  entered: { opacity: 1, height: "100%", transition: "opacity 500ms ease-in" },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
  unmounted: { display: "none" }
};

const skeletonFade = keyframes({
  "0%": { opacity: 1 },
  "25%": { opacity: 0.4 },
  "100%": { opacity: 1 }
});

export const LoadingAnimation = style({
  animationName: skeletonFade,
  animationDuration: "1.5s",
  animationIterationCount: "infinite",
  animationTimingFunction: "linear"
});
