import * as React from "react";

const MissingImage: React.FunctionComponent<{
  crop: {
    height: number;
    width: number;
  };
  className?: string;
}> = props => {
  const scale = props.crop.width / 580;
  const translate = (props.crop.height - scale * 400) / 2;
  return (
    <React.Fragment>
      <svg
        className={props.className}
        viewBox={`0 0 ${props.crop.width} ${props.crop.height}`}
        height={"100%"}
        width={"100%"}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <rect
            fill="#cccccc"
            height={props.crop.height + 2}
            width={props.crop.width + 2}
            y="-1"
            x="-1"
          />
          <g
            display="none"
            overflow="visible"
            y="0"
            x="0"
            height="100%"
            width="100%"
          >
            <rect
              fill="url(#gridpattern)"
              strokeWidth="0"
              y="0"
              x="0"
              height="100%"
              width="100%"
            />
          </g>
        </g>
        <g transform={`scale(${scale}) translate(0,${translate})`}>
          <path
            d="m160.499993,91.999996l0,215.999999l259.000002,0l0,-215.999999l-259.000002,0zm218.231483,197.460015l-177.462965,0l0,-178.920032l177.462965,0l0,178.920032zm-134.296298,-108.802722c13.679037,0 24.777667,-11.189755 24.777667,-24.981105c0,-13.801021 -11.09863,-24.981105 -24.777667,-24.981105c-13.68863,0 -24.777667,11.189755 -24.777667,24.981105c0,13.791349 11.089037,24.981105 24.777667,24.981105zm124.713297,21.760544l-47.166778,-47.554043l-66.351964,66.896749l-18.389,-18.539984l-26.398815,26.615564l0,49.952539l158.306557,0l0,-77.370825z"
            fillOpacity="null"
            strokeOpacity="null"
            strokeWidth="0"
            stroke="#000"
            fill="#fff"
          />
        </g>
      </svg>
    </React.Fragment>
  );
};

export default MissingImage;
