import * as React from "react";
import { SearchField, SearchFieldIcon, SearchInput } from "./styles";
import IconSearch from "../../assets/partials/icon_search";
import { SearchBarProps } from "../../helpers/props";
import { debounce } from "../../helpers/debounce";
import { useIntl } from "react-intl";
import IconCross from "../../assets/partials/icon_cross";

const SearchBar: React.FunctionComponent<SearchBarProps> = ({ updateSearchTerm, setActive, suggestion, suggestionClicked }) => {
  const [search, updateSearch] = React.useState("");
  const debouncedSearch = React.useMemo(() =>
    debounce(e => updateSearchTerm(e), 150),
    [updateSearchTerm]
  );
  const intl = useIntl();

  React.useEffect(() => {
    if (suggestionClicked){
      updateSearch(suggestion);
    }
  }, [suggestion, suggestionClicked]);

  return (
    <React.Fragment>
      <div className={SearchField}>
        <div className={SearchFieldIcon}>
          <IconSearch />
        </div>
        <input className={SearchInput}
          aria-label={"Søk etter arrangement"}
          placeholder={intl.formatMessage({ id: "containers.Search.placeholder", defaultMessage: "Finn et arrangement" })}
          onFocus={() => setActive(true)}
          onChange={e => {
            updateSearch(e.target.value);
            debouncedSearch(e.target.value);
            if (e.target.value === "") updateSearchTerm("");
          }}
          value={capitalize(search)}
        />
        {search ? (<div className={SearchFieldIcon} style={{ marginRight: 17, marginTop: 6 }} onClick={() => {
          updateSearch("");
          updateSearchTerm("")
        }}>
          {<IconCross />}
        </div>) : null}
      </div>
    </React.Fragment >
  );
};

function capitalize(input: string) {
  return input.charAt(0).toUpperCase() + input.slice(1);
}

export default SearchBar;
