import * as React from "react";

export const SkeletonSvg: React.FunctionComponent<{
  crop: { width: number; height: number };
  className?: string;
}> = props => (
  <React.Fragment>
    <svg
      viewBox={`0 0 ${props.crop.width} ${props.crop.height}`}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        fill="#b2b2b2"
        height={props.crop.height}
        width={props.crop.width}
        y="-1"
        x="-1"
      />
    </svg>
  </React.Fragment>
);
