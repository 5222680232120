import * as React from "react";
import { PaymentCardSvg } from "./styles";

const IconVipps: React.FunctionComponent = () => (
  <React.Fragment>
    <svg
      className={PaymentCardSvg}
      width="29"
      height="23"
      viewBox="0 0 29 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.24761 14.2231C0.482121 13.0749 0.312012 12.4511 0.312012 11.8416C0.312012 10.424 1.24762 9.46003 2.52344 9.46003C3.41651 9.46003 4.29541 9.97035 5.20266 11.2887C7.54167 14.6909 10.8163 17.3134 15.1966 17.3134C19.1481 17.3134 21.4871 15.2296 23.4008 12.7063C24.464 11.2887 25.4421 10.8351 26.2927 10.8351C27.5685 10.8351 28.4191 11.8557 28.4191 13.089C28.4191 13.7978 28.1214 14.62 27.2708 15.7257C24.3364 19.6241 20.2538 22.1863 15.0265 22.1863C9.32782 22.2147 4.43717 19.0641 1.24761 14.2231ZM22.6779 3.6763C22.6779 4.33267 22.4831 4.97429 22.1183 5.51994C21.7535 6.06559 21.235 6.49075 20.6285 6.74161C20.022 6.99246 19.3546 7.05774 18.711 6.92917C18.0673 6.8006 17.4763 6.48397 17.0127 6.01935C16.549 5.55473 16.2337 4.96302 16.1065 4.31909C15.9793 3.67516 16.046 3.00797 16.2981 2.40197C16.5503 1.79597 16.9766 1.27839 17.523 0.914757C18.0694 0.551119 18.7115 0.357766 19.3678 0.359168C19.8047 0.353431 20.2383 0.435365 20.6429 0.600125C21.0476 0.764884 21.4151 1.00912 21.7237 1.31839C22.0323 1.62766 22.2757 1.99568 22.4396 2.40069C22.6035 2.80569 22.6845 3.23945 22.6779 3.6763Z"
        fill="#FF5B24"
      />
    </svg>
  </React.Fragment>
);

export default IconVipps;
