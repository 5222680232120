import { media, style } from "typestyle";
import breakpoints from "../../constants/breakpoints";

export const Brand = style(
  {
    display: "block",
    width: "111px",
    height: "34px"
  },
  media({ maxWidth: breakpoints.breakpointMedium }, { width: "90px" })
);

export const FooterBrand = style(
  {},
  media({ maxWidth: breakpoints.breakpointMedium }, { marginBottom: "20px" })
);
