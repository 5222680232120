import * as React from "react";
import { FormattedMessage } from "react-intl";
import { PromoSectionDescription, PromoSectionTitle } from "./styles";

const PromoContent: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <div>
        <h2 className={PromoSectionTitle}><FormattedMessage id="containers.PromoContent.sell" defaultMessage="Selg billetter. Helt enkelt." /> </h2>
        <p className={PromoSectionDescription}>
          <FormattedMessage id="containers.PromoContent.description" defaultMessage="Over 3000 norske arrangører bruker Hoopla som sitt billettsystem. Prøv du også, og selg dine første billetter innen 5 minutter!" />
        </p>
      </div>
    </React.Fragment>
  );
};

export default PromoContent;
