import { defineMessages, MessageDescriptor } from "react-intl";
export type CategoryName =
  | "CONCERT"
  | "CONFERENCE"
  | "FESTIVAL"
  | "SEMINAR"
  | "COURSE"
  | "SHOW"
  | "SPORTS"
  | "EXHIBITION"
  | "GATHERING"
  | "OTHER";

export const categoryNameMessages = defineMessages<CategoryName>({
  CONCERT: {
    id: "constants.eventCategoryNames.concert",
    defaultMessage: "Concert",
  },
  CONFERENCE: {
    id: "constants.eventCategoryNames.conference",
    defaultMessage: "Conference",
  },
  FESTIVAL: {
    id: "constants.eventCategoryNames.festival",
    defaultMessage: "Festival",
  },
  SEMINAR: {
    id: "constants.eventCategoryNames.seminar",
    defaultMessage: "Lecture",
  },
  COURSE: {
    id: "constants.eventCategoryNames.course",
    defaultMessage: "Course",
  },
  SHOW: {
    id: "constants.eventCategoryNames.show",
    defaultMessage: "Show",
  },
  SPORTS: {
    id: "constants.eventCategoryNames.sports",
    defaultMessage: "Sports",
  },
  EXHIBITION: {
    id: "constants.eventCategoryNames.exhibition",
    defaultMessage: "Exhibition",
  },
  GATHERING: {
    id: "constants.eventCategoryNames.gathering",
    defaultMessage: "Gathering/Party",
  },
  OTHER: {
    id: "constants.eventCategoryNames.other",
    defaultMessage: "Other…",
  },
});

export function isOfType(category: string) {
  return ['CONCERT', 'CONFERENCE', 'FESTIVAL', 'SEMINAR', 'SHOW', 'SPORTS', 'EXHIBITION', 'GATHERING', 'OTHER'].includes(category)
}

const categoryHelper = (category: CategoryName): MessageDescriptor => {
  return categoryNameMessages[category];
};
export default categoryHelper;
