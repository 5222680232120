import * as React from "react";
import { PaymentCardSvg } from "./styles";

const IconVisa: React.FunctionComponent = () => (
  <React.Fragment>
    <svg
      className={PaymentCardSvg}
      width="38"
      height="25"
      viewBox="0 0 38 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4.7019H38V20.2981H0V4.7019Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.5C0 0.671573 0.671573 0 1.5 0H36.5C37.3284 0 38 0.671573 38 1.5V4.70192H0V1.5Z"
        fill="#2F3A7D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 20.2981H38V23.5C38 24.3284 37.3284 25 36.5 25H1.5C0.671574 25 0 24.3284 0 23.5V20.2981Z"
        fill="#F4B662"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.8249 8.32005C23.4354 8.14979 22.8228 7.96399 22.0596 7.96399C20.1119 7.96399 18.7409 9.11536 18.7295 10.7639C18.7171 11.9831 19.7079 12.6633 20.4548 13.0686C21.2224 13.484 21.4804 13.7499 21.4773 14.1214C21.472 14.6895 20.8646 14.9499 20.2982 14.9499C19.5095 14.9499 19.0904 14.8217 18.4426 14.505L18.1891 14.3699L17.9137 16.2689C18.373 16.5052 19.2246 16.7103 20.1084 16.721C22.1784 16.721 23.5234 15.5837 23.5391 13.8233C23.5462 12.8564 23.0213 12.1233 21.8844 11.5182C21.196 11.1249 20.7734 10.8643 20.7783 10.4662C20.7787 10.1135 21.1352 9.73615 21.9068 9.73615C22.5512 9.72462 23.0177 9.88962 23.3812 10.062L23.5581 10.1591L23.8249 8.32005ZM14.709 16.5953L15.9414 8.11183H17.9114L16.6791 16.5953H14.709ZM27.2328 11.2164C27.2328 11.2164 26.6113 13.0999 26.448 13.5875H28.0835C28.0056 13.1634 27.6276 11.1386 27.6276 11.1386L27.4947 10.4069C27.4421 10.5751 27.3687 10.7979 27.3129 10.9674C27.2631 11.1184 27.2274 11.2269 27.2328 11.2164ZM28.8786 8.12047H27.3561C26.8839 8.12047 26.5304 8.27056 26.3236 8.82369L23.398 16.5983H25.4671C25.4671 16.5983 25.8047 15.5524 25.8813 15.3229C26.107 15.3229 28.1176 15.3272 28.4045 15.3272C28.4636 15.6233 28.6444 16.5983 28.6444 16.5983H30.4729L28.8786 8.12047Z"
        fill="#2F3A7D"
      />
      <path
        opacity="0.9"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.16102 8.11316H9.33786C9.76569 8.13195 10.1118 8.29131 10.2309 8.82891L10.9211 12.7258C10.9212 12.7262 10.9213 12.7266 10.9214 12.727L11.1279 13.9027L13.0561 8.119H15.1437L12.0414 16.5865L9.95645 16.5879L8.20457 9.21958C7.59886 8.8255 6.9077 8.50865 6.13477 8.28893L6.16102 8.11316Z"
        fill="#18246F"
      />
    </svg>
  </React.Fragment>
);

export default IconVisa;
