import { extend, media, style } from "typestyle";
import { Section } from "../../styles/section";
import breakpoints from "../../constants/breakpoints";
import colors from "../../constants/colors";
import sizes from "../../constants/sizes";

export const EventSection = style(
  extend(Section, {
    paddingBottom: "160px"
  }),
  media(
    { maxWidth: breakpoints.breakpointMedium },
    {
      background: colors.hooplaWhite,
      paddingTop: 0,
      paddingBottom: "80px"
    }
  ),
  media(
    { maxWidth: breakpoints.breakpointSmall },
    {
      paddingBottom: "50px"
    }
  )
);

export const badges = style(
  {
    marginBottom: "1.25rem"
  }
);

export const LoadMoreContainer = style({
  textAlign: "center"
});

export const Events = style(
  {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: sizes.contentMaxWidth,
    margin: "0 auto",
    marginBottom: "50px"
  },
  media(
    { maxWidth: breakpoints.breakpointMedium },
    {
      flexDirection: "column",
      maxWidth: "340px"
    }
  )
);

export const Eventmod3 = style(
  {
    flex: "0 0 calc(100%/3 - 14px)",
    borderRadius: "8px",
    background: "#f7f7f7",
    marginRight: "21px",
    overflow: "hidden",
    cursor: "pointer",
    textDecoration: "none",
    color: colors.hooplaTextColor,
    $nest: {
      "&:not(:last-child)": {
        marginBottom: "32px"
      }
      /*"&:hover": {
        background: "#f0f0f0"
      }*/
    }
  },
  media(
    { minWidth: breakpoints.breakpointMedium + 1 },
    {
      $nest: {
        "&:nth-child(3n)": {
          marginRight: 0
        },
        "&:nth-last-child(-n+3)": {
          marginBottom: 0
        }
      }
    }
  ),
  media(
    { maxWidth: breakpoints.breakpointMedium },
    {
      display: "flex",
      flex: "0 0 100%",
      marginRight: 0,
      background: "none",
      boxShadow: "none"
    }
  )
);

export const EventNotMod3 = style(
  {
    flex: "0 0 calc(100%/3 - 14px)",
    borderRadius: "8px",
    background: "#f7f7f7",
    marginRight: "21px",
    overflow: "hidden",
    cursor: "pointer",
    textDecoration: "none",
    color: colors.hooplaTextColor,
    $nest: {
      "&:not(:last-child)": {
        marginBottom: "32px"
      }
      /*"&:hover": {
        background: "#f0f0f0"
      }*/
    }
  },
  media(
    { minWidth: breakpoints.breakpointMedium + 1 },
    {
      $nest: {
        "&:nth-child(3n)": {
          marginRight: 0
        },
        "&:nth-last-child(-n+3)": {
          marginBottom: "32px"
        }
      }
    }
  ),
  media(
    { maxWidth: breakpoints.breakpointMedium },
    {
      display: "flex",
      flex: "0 0 100%",
      marginRight: 0,
      background: "none",
      boxShadow: "none"
    }
  )
);

export const EventImage = style(
  {},
  media(
    { maxWidth: breakpoints.breakpointMedium },
    {
      position: "relative",
      display: "block",
      flex: "0 0 105px",
      width: "105px",
      height: "105px",
      overflow: "hidden",
      borderRadius: "8px"
    }
  )
);

export const EventImageImg = style(
  {
    display: "block",
    width: "100%"
  },
  media(
    { maxWidth: breakpoints.breakpointMedium },
    { width: "169px", position: "absolute", top: 0, left: "calc(50% - 85px)" }
  )
);

export const EventTitle = style({
  fontSize: "20px",
  fontWeight: 800,
  marginBottom: "10px"
});

export const EventDescription = style({
  fontSize: "14px",
  fontWeight: 800,
  marginBottom: "10px"
});

export const EventDetail = style({
  $debugName: "EventDetail",
  display: "flex",
  fontSize: "13.5px",
  $nest: {
    "&:not(:last-child)": {
      marginBottom: "7px"
    },
    "&>p": {
      opacity: 0.65
    }
  }
});

export const EventDate = style({
  opacity: 0.65
})

export const EventIcon = style({
  $debugName: "EventIcon",
  marginRight: "6px",
  alignSelf: "center"
});

export const EventTitleAndDetails = style(
  {
    padding: "16px 18px 16px"
  },
  media(
    { maxWidth: breakpoints.breakpointMedium },
    {
      padding: "0 0 0 19px"
    }
  )
);
