import * as React from "react";
import ReactTransitionGroup from "react-addons-transition-group";
import { childAnimation, placeholderAnimation } from "./styles";

export const SkeletonTransition: React.FunctionComponent<{
  ready: boolean;
  placeholder: JSX.Element;
  children: JSX.Element;
  className?: string;
  appear?: boolean;
}> = (props) => {
  return (
    <React.Fragment>
      <ReactTransitionGroup component="div">
        {!props.ready ? (
          <div style={placeholderAnimation["entered"]}>{props.placeholder}</div>
        ) : (
          <div style={childAnimation["entered"]}>{props.children}</div>
        )}
      </ReactTransitionGroup>
    </React.Fragment>
  );
};
