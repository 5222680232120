import * as React from "react";
import { EventImageProps } from "../../helpers/props";
import LazyImageRenderer from "../../components/LazyImageRenderer";
import { eventCrop } from "../../constants/crops";
import { newImageCrop } from "../../helpers/newImageCrop";
import { useMedia } from "react-media";
import { globalQueries } from "../../styles/mediaQueries";

const EventImageModule: React.FunctionComponent<EventImageProps> = (props) => {
  const imageCrop = newImageCrop(props.url, props.imageCrop);
  const matches = useMedia({ queries: globalQueries });

  return (
    <React.Fragment>
      {matches.upToTablet && (
        <LazyImageRenderer
          imgixImage={imageCrop}
          croppedImages={props.images}
          base={{
            width: 105,
            ar: "Square",
          }}
          style={{ display: "block", width: "100%", aspectRatio: "1" }}
          queries={[]}
          alt={props.name}
          missingImageClass={props.className}
          missingImageCrop={eventCrop}
        />
      )}
      {matches.fromTablet && (
        <LazyImageRenderer
          imgixImage={imageCrop}
          croppedImages={props.images}
          base={{
            width: 315,
            ar: "SixteenByNine",
          }}
          style={{ display: "block", width: "100%", aspectRatio: "16/9" }}
          queries={[
            {
              type: "MinWidth",
              minWidth: 1100,
              size: { width: 320, ar: "SixteenByNine" },
              style: { display: "block", width: "100%", aspectRatio: "16/9" },
            },
          ]}
          alt={props.name}
          missingImageClass={props.className}
          missingImageCrop={eventCrop}
        />
      )}
    </React.Fragment>
  );
};

export default EventImageModule;
