import * as React from "react";
import {
  BrandAndNavigation,
  BrandAndNavigationFooterNavigation,
  BrandAndNavigationNavigationItem
} from "./styles";
import HooplaLogo from "../../components/HooplaLogo";
import { FormattedMessage } from "react-intl";

const BrandAndNavigationComponent: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <div className={BrandAndNavigation}>
        <div>
          <HooplaLogo footer />
          <div style={{margin: "0.5rem 0 0.5rem -0.25rem"}}>
            <iframe title="Status "src="https://status.hoopla.no/badge?theme=light" width="250" height="30"/>
          </div>
        </div>
        <div className={BrandAndNavigationFooterNavigation}>
          <a
            href={`https://my.${window.ENVIRONMENT.domain}`}
            className={BrandAndNavigationNavigationItem}
          >
            <FormattedMessage id="components.Footer.myPage" defaultMessage="Min side" />
          </a>
          <a
            href={`https://users.${window.ENVIRONMENT.domain}`}
            className={BrandAndNavigationNavigationItem}
          >
            <FormattedMessage id="components.Footer.login" defaultMessage="Logg inn som arrangør" />
          </a>
          <a
            href={`https://${window.ENVIRONMENT.domain}/arranger`}
            target="_blank"
            rel="noopener noreferrer"
            className={BrandAndNavigationNavigationItem}
          >
            <FormattedMessage id="components.Footer.sellTickets" defaultMessage="Selg billetter" />
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BrandAndNavigationComponent;
