import { media, style } from "typestyle";
import colors from "../../constants/colors";
import sizes from "../../constants/sizes";
import breakpoints from "../../constants/breakpoints";
import { px5 } from "../../styles/spacing";

const MENU_SPACING = "1rem";

export const AppHeader = style(
  {
    width: "100%",
    background: colors.hooplaBackgroundDarker,
    padding: "22px " + sizes.contentPadding
  },
  media(
    { maxWidth: breakpoints.breakpointMedium },
    {
      paddingLeft: sizes.contentPaddingSmall,
      paddingRight: sizes.contentPaddingSmall,
      display: "table"
    }
  )
);

export const AppHeaderInner = style({

  margin: "0 auto",
  maxWidth: sizes.contentMaxWidth,
  display: "flex"
},
  media(
    { maxWidth: breakpoints.breakpointSmall },
    {
      justifyContent: "space-between",
    }
  ),
);

export const AppHeaderNavigation = style({
  display: "flex",
  marginLeft: "auto"
});

export const AppHeaderNavigationItem = style(
  {
    display: "flex",
    padding: "8px",
    height: "30px",
    textDecoration: "none",
    color: colors.hooplaTextColor,
    cursor: "pointer",
    fontSize: "14px",
    border: 0,
    $nest: {
      "&:not(:last-child)": {
        marginLeft: "6px"
      }
      /*"&:hover": {
        opacity: 0.9
      }*/
    }
  },

  media(
    { maxWidth: breakpoints.breakpointMedium },
    {
      background: colors.hooplaBackground,
      borderRadius: sizes.borderRadius,
    }
  ),
  media(
    { minWidth: breakpoints.breakpointMedium + 1 },
    {
      fontSize: "14px",
      background: colors.hooplaWhite,
      borderRadius: "6px",
      boxShadow: "0 3px 6px " + colors.hooplaShadowColor
    }
  )
);

export const AppHeaderNavigationItemIcon = style({
  marginTop: "2px",
  marginRight: "8px"
});

export const AppHeaderButtonText = style({
  display: "flex",
  //alignSelf: "center"
  lineHeight: 1,
})

export const menuToggler = style(px5, {
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  color: colors.hooplaGray,
  cursor: "pointer",
  fontSize: 14,
  background: "inherit",
  border: 0,
});

export const menu = style(
  {
    position: "absolute",
    top: "2.5rem",
    right: "0",

    backgroundColor: "white",
    zIndex: 10,
    display: "none",
  },
);

export const menuOpen = style({ display: "block" });

export const menuTop = style({
  margin: `${MENU_SPACING} 0 0.5rem`,
  padding: `0 ${MENU_SPACING}`,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: colors.hooplaTextColor,
});

export const menuClose = style({
  cursor: "pointer",
  padding: "0.25rem 0.5rem",
  background: "inherit",
  border: 0,
});

export const menuItem = style({
  padding: `1rem ${MENU_SPACING}`,
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  background: "inherit",
  border: 0,
  width: "100%",
  paddingLeft: "2.0rem",
  paddingRight: "2.0rem",

  $nest: {
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.05)",
      textDecoration: "none",
    },
    svg: {
      marginRight: "1.25rem",
    },
  },
});

export const flag = style({
  display: "flex",
  marginRight: "-6px",
  $nest: {
    svg: {
      height: "18px",
      borderRadius: "150%",
    },
  },
});
