import * as React from "react";

const IconNets: React.FunctionComponent = () => (
  <React.Fragment>
    <svg
      style={{ display: "block" }}
      width="100"
      height="23"
      viewBox="0 0 162 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M80.9998 24.6001C80.7998 24.6001 80.5999 24.5 80.5999 24.2V8.40004C80.5999 8.20004 80.6998 8.00004 80.8998 7.90004C82.7998 7.10004 85.0998 6.60005 87.3998 6.60005C91.9998 6.60005 95.0999 8.70005 95.0999 14V24.3C95.0999 24.5 94.9998 24.7 94.6998 24.7H92.6998C92.4998 24.7 92.2999 24.6 92.2999 24.3V14C92.2999 10.5 90.4998 9.10005 87.3998 9.10005C85.9998 9.10005 84.3998 9.40004 83.3998 9.70004V24.2C83.3998 24.4 83.2998 24.6001 82.9998 24.6001H80.9998Z"
        fill="#005677"
      />
      <path
        d="M113.4 16.7C113.4 17 113.3 17.1001 113 17.1001H101.9V18.5C101.9 21.1 103.8 22.4 106.3 22.4C108.7 22.4 110.3 21.5 111.5 20.5C111.7 20.4 111.9 20.4001 112 20.6001L113 21.9C113.2 22.1 113.1 22.3 112.9 22.4C111.2 23.8 109 24.9 106.2 24.9C102.5 24.9 98.9998 22.8 98.9998 18.5V13.3C98.9998 8.60005 102.5 6.50005 106.2 6.50005C109.9 6.50005 113.3 8.60005 113.3 13.3L113.4 16.7ZM106.3 9.10005C103.7 9.10005 101.9 10.4 101.9 13.4V14.8H110.7V13.4C110.7 10.5 108.8 9.10005 106.3 9.10005Z"
        fill="#005677"
      />
      <path
        d="M123.3 22.4C123.7 22.4 124.3 22.4 124.9 22.3C125.2 22.3 125.3 22.4 125.3 22.7V24.5C125.3 24.7 125.2 24.9 125 24.9C124.4 24.9 123.6 25 123.2 25C119.8 25 117.3 23.2 117.3 19V3.10004C117.3 2.80004 117.4 2.70004 117.7 2.70004H119.5C119.7 2.70004 119.8 2.80004 119.9 3.10004L120.1 7.20004H124.5C124.7 7.20004 124.9 7.30005 124.9 7.60005V9.30005C124.9 9.60005 124.8 9.70004 124.5 9.70004H120V18.9C120 21.1 121.2 22.4 123.3 22.4Z"
        fill="#005677"
      />
      <path
        d="M134 25C131.6 25 129.3 24.3 127.3 22.8C127.1 22.7 127.1 22.5 127.2 22.3L128.1 20.7C128.2 20.5 128.4 20.4001 128.6 20.6001C130.2 21.8001 132.1 22.5 134.1 22.5C136.4 22.5 138.1 21.5 138.1 19.8C138.1 17.9 136 17.4001 134.1 17.1001C131.6 16.6001 127.5 15.6 127.5 11.7C127.5 8.20004 130.8 6.70004 134.2 6.70004C136.3 6.70004 138.3 7.20005 140.1 8.30005C140.3 8.40005 140.4 8.60005 140.2 8.80005L139.3 10.3C139.2 10.5 139 10.6 138.8 10.4C137.4 9.70004 135.7 9.10005 134 9.10005C131.6 9.10005 130.2 10.1001 130.2 11.6001C130.2 13.5001 132.2 14 134.6 14.5C137.3 15 140.9 15.8001 140.9 19.6001C141 23.1001 138 25 134 25Z"
        fill="#005677"
      />
      <path
        d="M150 9.20004C152.209 9.20004 154 7.40918 154 5.20004C154 2.9909 152.209 1.20004 150 1.20004C147.791 1.20004 146 2.9909 146 5.20004C146 7.40918 147.791 9.20004 150 9.20004Z"
        fill="#00BEF0"
      />
      <path
        d="M158.4 7.70004C159.78 7.70004 160.9 6.58075 160.9 5.20004C160.9 3.81933 159.78 2.70004 158.4 2.70004C157.019 2.70004 155.9 3.81933 155.9 5.20004C155.9 6.58075 157.019 7.70004 158.4 7.70004Z"
        fill="#00BEF0"
      />
      <path
        d="M158.4 17.1001C160.333 17.1001 161.9 15.533 161.9 13.6001C161.9 11.6671 160.333 10.1001 158.4 10.1001C156.467 10.1001 154.9 11.6671 154.9 13.6001C154.9 15.533 156.467 17.1001 158.4 17.1001Z"
        fill="#00BEF0"
      />
      <path
        d="M150 25C151.657 25 153 23.6569 153 22C153 20.3432 151.657 19 150 19C148.343 19 147 20.3432 147 22C147 23.6569 148.343 25 150 25Z"
        fill="#00BEF0"
      />
      <path
        d="M71.5998 31.3C71.4998 31.3 71.2998 31.1 71.2998 30.9V0.700043C71.2998 0.500043 71.3998 0.300049 71.5998 0.300049C71.7998 0.300049 71.8998 0.500043 71.8998 0.700043V30.9C71.8998 31.1 71.6998 31.3 71.5998 31.3Z"
        fill="#76777A"
      />
      <path d="M62.5999 6.90004H58.0999V24.7H62.5999V6.90004Z" fill="#2D32AA" />
      <path
        d="M9.0999 6.40004C4.7999 6.40004 0.399902 7.90004 0.399902 7.90004V24.7H4.9999V11C4.9999 11 6.6999 10.3 9.0999 10.3C12.0999 10.3 13.3999 11.9 13.3999 14.5C13.3999 14.8 13.3999 24.7 13.3999 24.7H17.9999C17.9999 24.5 17.9999 14.8 17.9999 14.5C17.9999 9.00005 15.3999 6.40004 9.0999 6.40004Z"
        fill="#2D32AA"
      />
      <path
        d="M56.7999 6.90004H51.2999L47.0999 12.1001L44.7999 9.20004C43.5999 7.70004 41.7999 6.90004 39.8999 6.90004H37.1999L44.1999 15.7L36.8999 24.7H42.3999L46.8999 19.2L49.4999 22.4C50.6999 23.8 52.4999 24.7 54.3999 24.7H57.0999L49.7999 15.6001L56.7999 6.90004Z"
        fill="#2D32AA"
      />
      <path
        d="M37.6999 21.2L34.6999 18.7C33.6999 19.8 32.0999 21.2 29.3999 21.2C27.2999 21.2 25.3999 20.0001 24.4999 18.1001L38.0999 15.7C38.0999 14.4 37.7999 13.1 37.3999 12C35.9999 8.70005 32.7999 6.30005 28.8999 6.30005C23.6999 6.30005 19.5999 10 19.5999 15.7C19.5999 21.2 23.4999 25.1001 29.2999 25.1001C33.6999 25.2001 36.2999 22.9 37.6999 21.2ZM28.8999 10.2C30.8999 10.2 32.5999 11.1 33.2999 12.8L24.0999 14.4C24.5999 11.7 26.5999 10.2 28.8999 10.2Z"
        fill="#2D32AA"
      />
    </svg>
  </React.Fragment>
);

export default IconNets;
