const s0 = "0";
const s1 = "0.25rem";
const s2 = "0.5rem";
const s3 = "0.75rem";
const s4 = "1rem";
const s5 = "1.25rem";
const s6 = "1.5rem";
const s8 = "2rem";
const s10 = "2.5rem";
const s12 = "3rem";
const s16 = "4rem";
const s20 = "5rem";
const s24 = "6rem";
const s32 = "8rem";
const s40 = "10rem";
const s48 = "12rem";
const s56 = "14rem";
const s64 = "16rem";

// padding
export const p0 = { padding: s0 };
export const p1 = { padding: s1 };
export const p2 = { padding: s2 };
export const p3 = { padding: s3 };
export const p4 = { padding: s4 };
export const p5 = { padding: s5 };
export const p6 = { padding: s6 };
export const p8 = { padding: s8 };
export const p10 = { padding: s10 };
export const p12 = { padding: s12 };
export const p16 = { padding: s16 };
export const p20 = { padding: s20 };
export const p24 = { padding: s24 };
export const p32 = { padding: s32 };
export const p40 = { padding: s40 };
export const p48 = { padding: s48 };
export const p56 = { padding: s56, $debugName: "$faen56$" };
export const p64 = { padding: s64, $debugName: "$faen64$" };

// padding horizontal
export const px0 = { paddingLeft: s0, paddingRight: s0 };
export const px1 = { paddingLeft: s1, paddingRight: s1 };
export const px2 = { paddingLeft: s2, paddingRight: s2 };
export const px3 = { paddingLeft: s3, paddingRight: s3 };
export const px4 = { paddingLeft: s4, paddingRight: s4 };
export const px5 = { paddingLeft: s5, paddingRight: s5 };
export const px6 = { paddingLeft: s6, paddingRight: s6 };
export const px8 = { paddingLeft: s8, paddingRight: s8 };
export const px10 = { paddingLeft: s10, paddingRight: s10 };
export const px12 = { paddingLeft: s12, paddingRight: s12 };
export const px16 = { paddingLeft: s16, paddingRight: s16 };
export const px20 = { paddingLeft: s20, paddingRight: s20 };
export const px24 = { paddingLeft: s24, paddingRight: s24 };
export const px32 = { paddingLeft: s32, paddingRight: s32 };
export const px40 = { paddingLeft: s40, paddingRight: s40 };
export const px48 = { paddingLeft: s48, paddingRight: s48 };
export const px56 = { paddingLeft: s56, paddingRight: s56 };
export const px64 = { paddingLeft: s64, paddingRight: s64 };

// padding vertical
export const py0 = { paddingTop: s0, paddingBottom: s0 };
export const py1 = { paddingTop: s1, paddingBottom: s1 };
export const py2 = { paddingTop: s2, paddingBottom: s2 };
export const py3 = { paddingTop: s3, paddingBottom: s3 };
export const py4 = { paddingTop: s4, paddingBottom: s4 };
export const py5 = { paddingTop: s5, paddingBottom: s5 };
export const py6 = { paddingTop: s6, paddingBottom: s6 };
export const py8 = { paddingTop: s8, paddingBottom: s8 };
export const py10 = { paddingTop: s10, paddingBottom: s10 };
export const py12 = { paddingTop: s12, paddingBottom: s12 };
export const py16 = { paddingTop: s16, paddingBottom: s16 };
export const py20 = { paddingTop: s20, paddingBottom: s20 };
export const py24 = { paddingTop: s24, paddingBottom: s24 };
export const py32 = { paddingTop: s32, paddingBottom: s32 };
export const py40 = { paddingTop: s40, paddingBottom: s40 };
export const py48 = { paddingTop: s48, paddingBottom: s48 };
export const py56 = { paddingTop: s56, paddingBottom: s56 };
export const py64 = { paddingTop: s64, paddingBottom: s64 };

// padding top
export const pt0 = { paddingTop: s0 };
export const pt1 = { paddingTop: s1 };
export const pt2 = { paddingTop: s2 };
export const pt3 = { paddingTop: s3 };
export const pt4 = { paddingTop: s4 };
export const pt5 = { paddingTop: s5 };
export const pt6 = { paddingTop: s6 };
export const pt8 = { paddingTop: s8 };
export const pt10 = { paddingTop: s10 };
export const pt12 = { paddingTop: s12 };
export const pt16 = { paddingTop: s16 };
export const pt20 = { paddingTop: s20 };
export const pt24 = { paddingTop: s24 };
export const pt32 = { paddingTop: s32 };
export const pt40 = { paddingTop: s40 };
export const pt48 = { paddingTop: s48 };
export const pt56 = { paddingTop: s56 };
export const pt64 = { paddingTop: s64 };

// padding right
export const pr0 = { paddingRight: s0 };
export const pr1 = { paddingRight: s1 };
export const pr2 = { paddingRight: s2 };
export const pr3 = { paddingRight: s3 };
export const pr4 = { paddingRight: s4 };
export const pr5 = { paddingRight: s5 };
export const pr6 = { paddingRight: s6 };
export const pr8 = { paddingRight: s8 };
export const pr10 = { paddingRight: s10 };
export const pr12 = { paddingRight: s12 };
export const pr16 = { paddingRight: s16 };
export const pr20 = { paddingRight: s20 };
export const pr24 = { paddingRight: s24 };
export const pr32 = { paddingRight: s32 };
export const pr40 = { paddingRight: s40 };
export const pr48 = { paddingRight: s48 };
export const pr56 = { paddingRight: s56 };
export const pr64 = { paddingRight: s64 };

// padding bottom
export const pb0 = { paddingBottom: s0 };
export const pb1 = { paddingBottom: s1 };
export const pb2 = { paddingBottom: s2 };
export const pb3 = { paddingBottom: s3 };
export const pb4 = { paddingBottom: s4 };
export const pb5 = { paddingBottom: s5 };
export const pb6 = { paddingBottom: s6 };
export const pb8 = { paddingBottom: s8 };
export const pb10 = { paddingBottom: s10 };
export const pb12 = { paddingBottom: s12 };
export const pb16 = { paddingBottom: s16 };
export const pb20 = { paddingBottom: s20 };
export const pb24 = { paddingBottom: s24 };
export const pb32 = { paddingBottom: s32 };
export const pb40 = { paddingBottom: s40 };
export const pb48 = { paddingBottom: s48 };
export const pb56 = { paddingBottom: s56 };
export const pb64 = { paddingBottom: s64 };

// padding left
export const pl0 = { paddingLeft: s0 };
export const pl1 = { paddingLeft: s1 };
export const pl2 = { paddingLeft: s2 };
export const pl3 = { paddingLeft: s3 };
export const pl4 = { paddingLeft: s4 };
export const pl5 = { paddingLeft: s5 };
export const pl6 = { paddingLeft: s6 };
export const pl8 = { paddingLeft: s8 };
export const pl10 = { paddingLeft: s10 };
export const pl12 = { paddingLeft: s12 };
export const pl16 = { paddingLeft: s16 };
export const pl20 = { paddingLeft: s20 };
export const pl24 = { paddingLeft: s24 };
export const pl32 = { paddingLeft: s32 };
export const pl40 = { paddingLeft: s40 };
export const pl48 = { paddingLeft: s48 };
export const pl56 = { paddingLeft: s56 };
export const pl64 = { paddingLeft: s64 };

// margin
export const m0 = { margin: s0 };
export const m1 = { margin: s1 };
export const m2 = { margin: s2 };
export const m3 = { margin: s3 };
export const m4 = { margin: s4 };
export const m5 = { margin: s5 };
export const m6 = { margin: s6 };
export const m8 = { margin: s8 };
export const m10 = { margin: s10 };
export const m12 = { margin: s12 };
export const m16 = { margin: s16 };
export const m20 = { margin: s20 };
export const m24 = { margin: s24 };
export const m32 = { margin: s32 };
export const m40 = { margin: s40 };
export const m48 = { margin: s48 };
export const m56 = { margin: s56 };
export const m64 = { margin: s64 };

// margin horizontal
export const mx0 = { marginLeft: s0, marginRight: s0 };
export const mx1 = { marginLeft: s1, marginRight: s1 };
export const mx2 = { marginLeft: s2, marginRight: s2 };
export const mx3 = { marginLeft: s3, marginRight: s3 };
export const mx4 = { marginLeft: s4, marginRight: s4 };
export const mx5 = { marginLeft: s5, marginRight: s5 };
export const mx6 = { marginLeft: s6, marginRight: s6 };
export const mx8 = { marginLeft: s8, marginRight: s8 };
export const mx10 = { marginLeft: s10, marginRight: s10 };
export const mx12 = { marginLeft: s12, marginRight: s12 };
export const mx16 = { marginLeft: s16, marginRight: s16 };
export const mx20 = { marginLeft: s20, marginRight: s20 };
export const mx24 = { marginLeft: s24, marginRight: s24 };
export const mx32 = { marginLeft: s32, marginRight: s32 };
export const mx40 = { marginLeft: s40, marginRight: s40 };
export const mx48 = { marginLeft: s48, marginRight: s48 };
export const mx56 = { marginLeft: s56, marginRight: s56 };
export const mx64 = { marginLeft: s64, marginRight: s64 };

// margin vertical
export const my0 = { marginTop: s0, marginBottom: s0 };
export const my1 = { marginTop: s1, marginBottom: s1 };
export const my2 = { marginTop: s2, marginBottom: s2 };
export const my3 = { marginTop: s3, marginBottom: s3 };
export const my4 = { marginTop: s4, marginBottom: s4 };
export const my5 = { marginTop: s5, marginBottom: s5 };
export const my6 = { marginTop: s6, marginBottom: s6 };
export const my8 = { marginTop: s8, marginBottom: s8 };
export const my10 = { marginTop: s10, marginBottom: s10 };
export const my12 = { marginTop: s12, marginBottom: s12 };
export const my16 = { marginTop: s16, marginBottom: s16 };
export const my20 = { marginTop: s20, marginBottom: s20 };
export const my24 = { marginTop: s24, marginBottom: s24 };
export const my32 = { marginTop: s32, marginBottom: s32 };
export const my40 = { marginTop: s40, marginBottom: s40 };
export const my48 = { marginTop: s48, marginBottom: s48 };
export const my56 = { marginTop: s56, marginBottom: s56 };
export const my64 = { marginTop: s64, marginBottom: s64 };

// margin top
export const mt0 = { marginTop: s0 };
export const mt1 = { marginTop: s1 };
export const mt2 = { marginTop: s2 };
export const mt3 = { marginTop: s3 };
export const mt4 = { marginTop: s4 };
export const mt5 = { marginTop: s5 };
export const mt6 = { marginTop: s6 };
export const mt8 = { marginTop: s8 };
export const mt10 = { marginTop: s10 };
export const mt12 = { marginTop: s12 };
export const mt16 = { marginTop: s16 };
export const mt20 = { marginTop: s20 };
export const mt24 = { marginTop: s24 };
export const mt32 = { marginTop: s32 };
export const mt40 = { marginTop: s40 };
export const mt48 = { marginTop: s48 };
export const mt56 = { marginTop: s56 };
export const mt64 = { marginTop: s64 };

// margin right
export const mr0 = { marginRight: s0 };
export const mr1 = { marginRight: s1 };
export const mr2 = { marginRight: s2 };
export const mr3 = { marginRight: s3 };
export const mr4 = { marginRight: s4 };
export const mr5 = { marginRight: s5 };
export const mr6 = { marginRight: s6 };
export const mr8 = { marginRight: s8 };
export const mr10 = { marginRight: s10 };
export const mr12 = { marginRight: s12 };
export const mr16 = { marginRight: s16 };
export const mr20 = { marginRight: s20 };
export const mr24 = { marginRight: s24 };
export const mr32 = { marginRight: s32 };
export const mr40 = { marginRight: s40 };
export const mr48 = { marginRight: s48 };
export const mr56 = { marginRight: s56 };
export const mr64 = { marginRight: s64 };

// margin bottom
export const mb0 = { marginBottom: s0 };
export const mb1 = { marginBottom: s1 };
export const mb2 = { marginBottom: s2 };
export const mb3 = { marginBottom: s3 };
export const mb4 = { marginBottom: s4 };
export const mb5 = { marginBottom: s5 };
export const mb6 = { marginBottom: s6 };
export const mb8 = { marginBottom: s8 };
export const mb10 = { marginBottom: s10 };
export const mb12 = { marginBottom: s12 };
export const mb16 = { marginBottom: s16 };
export const mb20 = { marginBottom: s20 };
export const mb24 = { marginBottom: s24 };
export const mb32 = { marginBottom: s32 };
export const mb40 = { marginBottom: s40 };
export const mb48 = { marginBottom: s48 };
export const mb56 = { marginBottom: s56 };
export const mb64 = { marginBottom: s64 };

// margin left
export const ml0 = { marginLeft: s0 };
export const ml1 = { marginLeft: s1 };
export const ml2 = { marginLeft: s2 };
export const ml3 = { marginLeft: s3 };
export const ml4 = { marginLeft: s4 };
export const ml5 = { marginLeft: s5 };
export const ml6 = { marginLeft: s6 };
export const ml8 = { marginLeft: s8 };
export const ml10 = { marginLeft: s10 };
export const ml12 = { marginLeft: s12 };
export const ml16 = { marginLeft: s16 };
export const ml20 = { marginLeft: s20 };
export const ml24 = { marginLeft: s24 };
export const ml32 = { marginLeft: s32 };
export const ml40 = { marginLeft: s40 };
export const ml48 = { marginLeft: s48 };
export const ml56 = { marginLeft: s56 };
export const ml64 = { marginLeft: s64 };
