import { useLayoutEffect, useMemo, useState } from "react";
import { MediaQuery } from "typestyle/lib/types";
import { mediaQueryToString } from "../styles/mediaQueries";

export default function useMediaQuery(mediaQuery: MediaQuery) {
  const mediaQueryString = useMemo(() => mediaQueryToString(mediaQuery), [mediaQuery]);
  const [matches, setMatches] = useState(() => window.matchMedia(mediaQueryString).matches);

  useLayoutEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQueryString);
    const listener = (e: MediaQueryListEvent) => setMatches(e.matches);
    mediaQueryList.addListener(listener);

    return () => mediaQueryList.removeListener(listener);
  }, [mediaQueryString]);

  return matches;
}
