import { extend, media, style } from "typestyle";
import { Section } from "../../styles/section";
import colors from "../../constants/colors";
import breakpoints from "../../constants/breakpoints";
import sizes from "../../constants/sizes";

export const PromoSection = style(
  extend(Section),
  {
    background: colors.hooplaPurple,
    paddingTop: "60px",
    paddingBottom: "100px",
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: "center"
  },
  media(
    { maxWidth: breakpoints.breakpointMedium },
    {
      paddingTop: "60px",
      paddingBottom: "100px",
      paddingLeft: 0,
      paddingRight: 0
    }
  )
);

export const PromoSectionInner = style(
  {
    margin: "0 auto",
    maxWidth: "490px"
  },
  media(
    { maxWidth: breakpoints.breakpointSmall },
    {
      paddingLeft: sizes.contentPaddingSmall,
      paddingRight: sizes.contentPaddingSmall,
      boxSizing: "border-box"
    }
  )
);

export const PromoSectionIllustration = style({
  marginBottom: "10px"
});

export const PromoSectionIllustrationImg = style({
  margin: "auto",
  maxWidth: "370px",
  width: "100%",
  aspectRatio: "740/711"
});

export const PromoSectionTitle = style({
  marginBottom: "20px",
  fontSize: "29px",
  fontWeight: 900
});

export const PromoSectionDescription = style({
  marginBottom: "30px",
  fontSize: "17px",
  lineHeight: 1.5
});
