import { extend, media, style } from "typestyle";
import { Section } from "../../styles/section";
import colors from "../../constants/colors";
import breakpoints from "../../constants/breakpoints";

export const Hero = style(
  extend(Section, {
    background: colors.hooplaBackgroundDarker,
    position: "relative",
    paddingTop: "120px",
    paddingBottom: "240px",
  }),
  media(
    {
      minWidth: breakpoints.breakpointMedium + 1,
      maxWidth: breakpoints.breakpointHero,
    },
    {
      paddingTop: "65px",
      paddingBottom: "240px",
    },
  ),
  media(
    { maxWidth: breakpoints.breakpointMedium },
    {
      borderBottom: "1px solid " + colors.hooplaBorderColor,
      paddingTop: "70px",
      paddingBottom: 0,
    },
  ),
);

export const HeroInner = style(
  {
    maxWidth: "600px",
    margin: "0 auto",
  },
  media({ maxWidth: breakpoints.breakpointMedium }, { width: "100%" }),
);

export const HeroTitle = style(
  {
    marginBottom: "40px",
    lineHeight: 1.25,
    fontWeight: 900,
    fontSize: "46px",
  },
  media(
    {
      maxWidth: breakpoints.breakpointHero,
    },
    { fontSize: "44px" },
  ),
  media(
    {
      maxWidth: breakpoints.breakpointMedium,
    },
    {
      fontSize: "40px",
      textAlign: "center",
    },
  ),
  media({ maxWidth: breakpoints.breakpointSmall }, { fontSize: "32px" }),
);
