import React from "react";
import { classes, style } from "typestyle";

const shadowClx = style({
  boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.05)",
  borderRadius: 10,
  backgroundColor: "white",
  overflow: "hidden",
});

type ShadowBoxProps = {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  children: JSX.Element | string | JSX.Element[];
};

const ShadowBox: React.FC<ShadowBoxProps> = (props) => (
  <div
    style={props.style}
    onClick={props.onClick}
    className={classes(shadowClx, props.className)}
  >
    {props.children}
  </div>
);

export default ShadowBox;