import * as React from "react";
import { Icon } from "./styles";

const IconKey = () => (
  <React.Fragment>
    <div className={Icon} style={{ width: "16px", height: "16px" }}>
      <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          opacity="0.2"
          d="M14.875 14.4262L14.875 12.6121C14.875 12.5723 14.867 12.5329 14.8514 12.4962C14.8358 12.4594 14.8129 12.4261 14.7842 12.398L9.97328 7.69061C10.4246 6.72351 10.5143 5.63199 10.2269 4.60672C9.9394 3.58144 9.29302 2.68757 8.40062 2.08124C7.50821 1.47491 6.4265 1.19465 5.34442 1.28942C4.26234 1.3842 3.24867 1.84797 2.48046 2.59974C1.71226 3.35151 1.23835 4.3435 1.1415 5.40243C1.04466 6.46137 1.33104 7.51994 1.95063 8.39326C2.57021 9.26658 3.48362 9.89913 4.5313 10.1804C5.57899 10.4617 6.69437 10.3739 7.68261 9.93229L8.03327 10.2748L7.63532 11.4437C7.60248 11.5419 7.59586 11.6466 7.61608 11.7479C7.6363 11.8492 7.68269 11.9438 7.75083 12.0226C7.81898 12.1014 7.9066 12.1618 8.00537 12.1981C8.10413 12.2344 8.21074 12.2453 8.31504 12.2299L9.80797 12.0116L10.2566 12.4506L9.84751 13.3311C9.79859 13.4359 9.78133 13.5523 9.79777 13.6665C9.81421 13.7807 9.86366 13.8879 9.9403 13.9755C10.0169 14.063 10.1176 14.1274 10.2303 14.1608C10.3431 14.1943 10.4632 14.1955 10.5767 14.1644L11.6945 13.8584L12.4935 14.6403C12.5222 14.6683 12.5564 14.6906 12.5939 14.7057C12.6314 14.7209 12.6716 14.7286 12.7122 14.7285L14.566 14.7285C14.648 14.7285 14.7266 14.6967 14.7845 14.64C14.8424 14.5833 14.875 14.5064 14.875 14.4262ZM4.5219 5.50397C4.33857 5.50397 4.15937 5.45077 4.00694 5.3511C3.85451 5.25143 3.73571 5.10977 3.66556 4.94402C3.5954 4.77828 3.57704 4.5959 3.61281 4.41994C3.64857 4.24399 3.73685 4.08236 3.86648 3.95551C3.99611 3.82865 4.16127 3.74226 4.34107 3.70726C4.52087 3.67226 4.70723 3.69023 4.8766 3.75888C5.04597 3.82753 5.19073 3.94379 5.29258 4.09296C5.39443 4.24213 5.44879 4.4175 5.44879 4.5969C5.44879 4.83747 5.35114 5.06819 5.17731 5.2383C5.00348 5.40841 4.76772 5.50397 4.5219 5.50397Z"
          fill="#191919"
        />
      </svg>
    </div>
  </React.Fragment>
);

export default IconKey;
