import * as React from "react";
import {
  Organizer,
  OrganizerDetails,
  OrganizerImage,
  OrganizerImageImg,
  Organizers,
  OrganizerTitle,
  OrganizerTitleAndDetails
} from "./styles";
import { TextRow } from "react-placeholder/lib/placeholders";
import { classes } from "typestyle";
import { LoadingAnimation } from "../../components/Skeleton/styles";
import { organizerCrop } from "../../constants/crops";
import { SkeletonSvg } from "../../assets/partials/skeletonSvg";

const OrganizerSkeleton: React.FunctionComponent = () => (
  <React.Fragment>
    <div className={Organizers}>
      {Array(4)
        .fill(0)
        .map((_, index) => (
          <div
            key={"organizer" + index}
            className={classes(Organizer, LoadingAnimation)}
          >
            <div className={OrganizerImage}>
              <SkeletonSvg crop={organizerCrop} className={OrganizerImageImg} />
            </div>
            <div className={OrganizerTitleAndDetails}>
              <TextRow color={"lightGray"} className={OrganizerTitle} />
              <TextRow color={"lightGray"} className={OrganizerDetails} />
            </div>
          </div>
        ))}
    </div>
  </React.Fragment>
);

export default OrganizerSkeleton;
