import * as React from "react";
import { PaymentCardSvg } from "./styles";

const IconMasterCard: React.FunctionComponent = () => (
  <React.Fragment>
    <svg
      className={PaymentCardSvg}
      width="38"
      height="25"
      viewBox="0 0 38 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.9609 7.19482H15.6582V17.4889H21.9609V7.19482Z"
        fill="#FF5F00"
      />
      <path
        d="M16.3078 12.343C16.307 11.3518 16.5318 10.3734 16.9649 9.48181C17.3981 8.59025 18.0284 7.80889 18.8082 7.19689C17.8424 6.43792 16.6826 5.96596 15.4613 5.83495C14.2401 5.70395 13.0066 5.91918 11.9018 6.45605C10.7971 6.99292 9.86564 7.82977 9.214 8.87095C8.56236 9.91214 8.2168 11.1157 8.2168 12.3439C8.2168 13.5722 8.56236 14.7757 9.214 15.8169C9.86564 16.8581 10.7971 17.695 11.9018 18.2318C13.0066 18.7687 14.2401 18.9839 15.4613 18.8529C16.6826 18.7219 17.8424 18.25 18.8082 17.491C18.0282 16.8788 17.3977 16.0972 16.9645 15.2053C16.5313 14.3134 16.3068 13.3346 16.3078 12.343V12.343Z"
        fill="#EB001B"
      />
      <path
        d="M29.4009 12.3428C29.4009 13.5712 29.0552 14.7748 28.4035 15.816C27.7517 16.8572 26.8202 17.6941 25.7153 18.2308C24.6104 18.7676 23.3768 18.9827 22.1554 18.8515C20.9341 18.7203 19.7743 18.2482 18.8086 17.4889C19.588 16.8764 20.2182 16.0948 20.6515 15.2032C21.0847 14.3116 21.3099 13.3332 21.3099 12.3419C21.3099 11.3506 21.0847 10.3722 20.6515 9.48057C20.2182 8.58895 19.588 7.80739 18.8086 7.19484C19.7743 6.43563 20.9341 5.96344 22.1554 5.83225C23.3768 5.70105 24.6104 5.91615 25.7153 6.45294C26.8202 6.98973 27.7517 7.82657 28.4035 8.86779C29.0552 9.90901 29.4009 11.1126 29.4009 12.341V12.3428Z"
        fill="#F79E1B"
      />
    </svg>
  </React.Fragment>
);

export default IconMasterCard;
