import { style } from "typestyle";

export const errorCard = style({
  display: "flex",
  alignContent: "center",
  flexDirection: "column",
  alignItems: "center",
  width: "330px",
  background: "white",
  padding: "16px 24px",
  borderRadius: "0.5rem",
  margin: "2rem auto",
  border: "1px solid rgba(0,0,0,0.2)",
});
