import * as React from "react";
import { ButtonWhite } from "./styles";
import { promoButtonLink } from "../../constants/links";
import { FormattedMessage } from "react-intl";

export const PromoButton: React.FunctionComponent = () => {
  const promoLink = promoButtonLink()
  return (
    
    <React.Fragment>
      <a
        href={promoLink}
        target="_blank"
        rel="noopener noreferrer"
        className={ButtonWhite}
      >
        <FormattedMessage id="components.PromoButton.learnHow" defaultMessage="Lær hvordan" />
      </a>
    </React.Fragment>
  );
};
