import * as React from "react";
import { PromoButton } from "../../components/Button";
import { PromoSection, PromoSectionInner } from "./styles";
import PromoContent from "./PromoContent";
import PromoImage from "./PromoImage";

const PromoModule: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <div className={PromoSection}>
        <div className={PromoSectionInner}>
          <PromoImage />
          <PromoContent />
          <PromoButton />
        </div>
      </div>
    </React.Fragment>
  );
};

export default PromoModule;
