import * as React from "react";
import { EventResultProps } from "../../helpers/props";
import {
  ResultImage,
  ResultInformation,
  SearchResult,
  SearchResultDescription,
  SearchResultDetail,
  SearchResultDetailIcon,
  SearchResultDetails,
  SearchResultTitle,
  ResultImageImg,
  SearchResultDate,
} from "./styles";
import IconVenue from "../../assets/partials/icon_venue";
import IconDate from "../../assets/partials/icon_date";
import { searchCrop } from "../../constants/crops";
import { searchLink } from "../../constants/links";
import LazyImageRenderer from "../../components/LazyImageRenderer";
import FormattedDateHelper from "../../helpers/FormattedDateHelper";
import { newImageCrop } from "../../helpers/newImageCrop";

const SearchResultEvent: React.FunctionComponent<EventResultProps> = (props) => {
  const imageCrop = newImageCrop(props.event.data.image, props.event.data.image_crop);
  return (
    <React.Fragment>
      <a
        href={searchLink(
          props.organization.identifier,
          props.event.identifier ? props.event.identifier : props.event.event_id,
        )}
        className={SearchResult}
      >
        <div className={ResultImage}>
          <LazyImageRenderer
            imgixImage={imageCrop}
            croppedImages={props.event.images}
            base={{
              width: 60,
              ar: "FourByThree",
            }}
            style={{ display: "block", width: "100%", aspectRatio: "4/3" }}
            queries={[]}
            alt={props.event.name}
            missingImageCrop={searchCrop}
            missingImageClass={ResultImageImg}
          />
        </div>
        <div className={ResultInformation}>
          <div className={SearchResultTitle}>{props.event.name}</div>
          {props.event.short_description && (
            <div className={SearchResultDescription}>{props.event.short_description}</div>
          )}
          <div className={SearchResultDetails}>
            {props.event.data.location?.name && (
              <div className={SearchResultDetail}>
                <div className={SearchResultDetailIcon}>
                  <IconVenue />
                </div>
                <p>{props.event.data.location.name}</p>
              </div>
            )}
            <div className={SearchResultDetail}>
              <div className={SearchResultDetailIcon}>
                <IconDate />
              </div>
              <div className={SearchResultDate}>
                <FormattedDateHelper
                  start={new Date(props.event.start)}
                  end={new Date(props.event.end)}
                  dateFormat={props.event.date_formats?.date_type}
                  text={props.event.date_formats?.date_text}
                ></FormattedDateHelper>
              </div>
            </div>
          </div>
        </div>
      </a>
    </React.Fragment>
  );
};

export default SearchResultEvent;
