export const getItem = (key: string, notSetValue: string) => {
    try {
      return localStorage.getItem(key) || sessionStorage.getItem(key) || notSetValue;
    } catch (x) {
      try {
        return sessionStorage.getItem(key) || notSetValue;
      } catch (y) {
        return notSetValue;
      }
    }
  };
  
  export const setItem = (key: string, value: string) => {
    try {
      localStorage.setItem(key, value);
    } catch (x) {
      try {
        sessionStorage.setItem(key, value);
      } catch (y) {
        /* no op */
      }
    }
  };
  