import { LazyImageTwicPic, Query, Size } from "@hooplab/ui-components";
import React, { CSSProperties } from "react";
import MissingImage from "../../assets/partials/missing_image";
import { EventImageCrops, ImgixImage, OrganizationImageCrops } from "../../helpers/props";

type RawQuery =
  | {
      type: "MinWidth";
      minWidth: number;
      size: Size;
      style?: CSSProperties;
    }
  | {
      type: "MaxWidth";
      maxWidth: number;
      size: Size;
      style?: CSSProperties;
    };

interface IProps {
  croppedImages: EventImageCrops | OrganizationImageCrops | null;
  imgixImage: ImgixImage | null;
  queries: RawQuery[];
  style?: CSSProperties;
  base: Size;
  alt?: string;
  missingImageClass?: string;
  missingImageCrop: { width: number; height: number };
}

const LazyImageRenderer: React.FC<IProps> = ({
  croppedImages,
  imgixImage,
  queries,
  base,
  alt,
  style,
  missingImageClass,
  missingImageCrop,
}) => {
  const getCropFromSize = (size: Size, images: EventImageCrops | OrganizationImageCrops) => {
    switch (size.ar) {
      case "SixteenByNine":
        return images.crop16x9;
      case "FourByThree":
        return images.crop4x3;
      case "Square":
        return images.crop1x1;
      default:
        return images.crop16x9;
    }
  };
  const envName = window.ENVIRONMENT.__IMGIX_ENV__;

  if (!croppedImages && imgixImage) {
    const q: Query[] = queries.map((s: RawQuery) => ({
      ...s,
      crop: {
        url: imgixImage.url,
        x: imgixImage.crop.offsetX,
        y: imgixImage.crop.offsetY,
        height: imgixImage.crop.height,
        width: imgixImage.crop.width,
      },
    }));
    const crop = {
      url: imgixImage.url,
      x: imgixImage.crop.offsetX,
      y: imgixImage.crop.offsetY,
      height: imgixImage.crop.height,
      width: imgixImage.crop.width,
    };
    return (
      <React.Fragment>
        <LazyImageTwicPic
          crop={crop}
          default={base}
          queries={q}
          environment={envName}
          alt={alt}
          style={style}
        />
      </React.Fragment>
    );
  } else if (croppedImages) {
    const q: Query[] = queries.map((s: RawQuery) => ({
      ...s,
      crop: getCropFromSize(s.size, croppedImages),
    }));
    const crop = getCropFromSize(base, croppedImages);

    return (
      <React.Fragment>
        <LazyImageTwicPic
          crop={crop}
          queries={q}
          default={base}
          forceCrop
          environment={envName}
          alt={alt}
          style={style}
        />
      </React.Fragment>
    );
  } else {
    return (
      <MissingImage
        className={missingImageClass}
        crop={{ width: missingImageCrop.width, height: missingImageCrop.height }}
      />
    );
  }
};

export default LazyImageRenderer;
