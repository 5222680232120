//TODO: change links in production
export const hooplaLink = "hoopla.no";

//TODO: add utm-tags to events and organizers

export const redirLink = (identifer: string, event?: number | string) => {
  return `https://${identifer}.${window.ENVIRONMENT.domain}/${
    event ? `event/${event}/` : ""
  }?utm_medium=featured_list&utm_source=${window.ENVIRONMENT.domain}`;
};

export const searchLink = (identifer: string, event?: number | string) => {
  return `https://${identifer}.${window.ENVIRONMENT.domain}/${
    event ? `event/${event}/` : ""
  }?utm_medium=search&utm_source=${window.ENVIRONMENT.domain}`;
};

export const promoMessageLink = () => {
  return `https://www.${window.ENVIRONMENT.domain}/arranger/?utm_source=${window.ENVIRONMENT.domain}&utm_medium=header_button`;
};

export const promoButtonLink = () => {
  return `https://www.${window.ENVIRONMENT.domain}/arranger/billettsystem/?utm_source=${window.ENVIRONMENT.domain}&utm_medium=footer_cta`;
};

export const termsLink = () => {
  return `https://www.${window.ENVIRONMENT.domain}/arranger/vilkar-billettkjopere/?utm_source=${window.ENVIRONMENT.domain}&utm_medium=footer_menu`;
};

export const privacyLink = () => {
  return `https://www.${window.ENVIRONMENT.domain}/arranger/personvern/?utm_source=${window.ENVIRONMENT.domain}&utm_medium=footer_menu`;
};

export const accesibilityLink = () => {
  return `https://www.${window.ENVIRONMENT.domain}/arranger/tilgjengelighet/?utm_source=${window.ENVIRONMENT.domain}&utm_medium=footer_menu`;
};
