import { extend, style } from "typestyle";
import colors from "../../constants/colors";

const Button = {
  border: 0,
  background: "transparent",
  fontSize: "16px",
  fontWeight: 700,
  padding: "12px 32px",
  borderRadius: "100px",
  cursor: "pointer",
  textDecoration: "none"
  /*$nest: {
    "&:hover": {
      opacity: 0.9
    }
  }*/
};

export const ButtonBlue = style(
  extend(Button, {
    color: colors.hooplaWhite,
    backgroundColor: colors.hooplaDarkBlue,
    boxShadow: "0 3px 6px " + colors.hooplaShadowColor,
    $nest:{
      "&:not(:last-child)":{
        marginRight: "10px"
      }
    }
  })
);

export const ButtonWhite = style(
  extend(Button, {
    color: colors.hooplaTextColorTinted,
    backgroundColor: colors.hooplaWhite,
    boxShadow: "0 3px 6px " + colors.hooplaShadowColor
  })
);

export const FilterButtonDiv = style({
  display: "flex",
  flexWrap: "wrap",
  margin: "20px auto",
  maxWidth: "1000px",
});
