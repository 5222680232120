import * as React from "react";
import BrandIcon from "../../assets/partials/icon_hoopla";
import { Brand, FooterBrand } from "./styles";
import { classes } from "typestyle";

const HooplaLogo: React.FunctionComponent<{footer?:boolean}> = (props) => {
  return (
    <React.Fragment>
      <a aria-label={"Hoopla-logo"} href={`https://www.${window.ENVIRONMENT.domain}`} className={props.footer?classes(Brand, FooterBrand):Brand}>
        <BrandIcon />
      </a>
    </React.Fragment>
  );
};

export default HooplaLogo;
