import * as React from "react";

import { AppFooter } from "./styles";
import InformationAndPayment from "./InformationAndPayment";
import BrandAndNavigationComponent from "./BrandAndNavigation";

const Footer: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <div className={AppFooter}>
        <BrandAndNavigationComponent />
        <InformationAndPayment />
      </div>
    </React.Fragment>
  );
};

export default Footer;
