import * as React from "react";
import { HeroTitle } from "./styles";
import { FormattedMessage } from "react-intl";

const HeroText: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <h1 className={HeroTitle}>
        <FormattedMessage id="containers.HeroModule.experiences" defaultMessage="Opplevelser" />.{" "}
        <br /> <FormattedMessage id="containers.HeroModule.easy" defaultMessage="Helt enkelt." />
      </h1>
    </React.Fragment>
  );
};

export default HeroText;
