import * as React from "react";
import { SectionTitleSmall } from "../../styles/section";
import { OrganizerSection, OrganizerSectionInner } from "./styles";
import { ApiCall } from "../../middleware/api";
import { SkeletonTransition } from "../../components/Skeleton/skeletonTransition";
import { OrganizerList } from "./OrganizerList";
import OrganizerSkeleton from "./OrganizerSkeleton";
import { FormattedMessage } from "react-intl";
import ErrorPage from "../ErrorPage";

const OrganizerListModule: React.FunctionComponent = () => {
  const [loading, setLoading] = React.useState(false);
  const [organizations, updateOrganizations] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [poll, setPoll] = React.useState(false);

  React.useEffect(() => {
    let timeoutId;
    setLoading(true);
    setError(false);
    let prom = ApiCall(`/api/public/v3.0/featured/organizations?limit=4&offset=0`);
    Promise.resolve(prom)
      .then((res) => {
        updateOrganizations(res["organizations"]);
        setLoading(false);
        timeoutId = setTimeout(() => setPoll(!poll), 1000 * 60 * 60);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
      });
    return clearTimeout(timeoutId);
  }, [poll]);

  return (
    <React.Fragment>
      {error ? (
        <ErrorPage />
      ) : (
        <div className={OrganizerSection}>
          <div className={OrganizerSectionInner}>
            <div className={SectionTitleSmall}>
              <FormattedMessage
                id="containers.OrganizerList.highlighted"
                defaultMessage="Utvalgte arrangører"
              />
            </div>
            <SkeletonTransition ready={!loading} placeholder={<OrganizerSkeleton />}>
              <OrganizerList organizations={organizations} />
            </SkeletonTransition>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default OrganizerListModule;
