import { media, style } from "typestyle";
import breakpoints from "../../constants/breakpoints";

export const Icon = style({
  display: "block",
  width: "16px",
  height: "16px"
});

export const IconPromoConfetti = style(
  {
    flex: "0 0 36px",
    display: "block",
    width: "36px",
    height: "25px",

  },
  media(
    { maxWidth: breakpoints.breakpointSmall },
    {
      flex: "0 0 30px",
      width: "30px"
    }
  )
);

export const PaymentCardSvg = style({
  display: "block",
  width: "100%"
});
