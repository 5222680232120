import * as React from "react";


const IconCross: React.FunctionComponent = () => (
    <React.Fragment>
        <div style={{ cursor: "pointer", }}>
            <svg
                style={{ fill: "#737476", width: 12, height: 12 }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
            >
                <path
                    fill="none"
                    stroke="#737476"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M.75 23.249l22.5-22.5M23.25 23.249L.75.749"
                />
            </svg>

        </div>
    </React.Fragment>
);

export default IconCross;
