const colors = {
  hooplaGray: "rgba(174,177,185,0.13)",
  hooplaShadowColor: "rgba(0,0,0,0.04)",
  hooplaTextColor: "rgb(22,23,26)",
  hooplaTextColor120: "rgb(89,89,89)",
  hooplaTextColorTinted: "rgb(20,57,128)",
  hooplaWhite: "rgb(255,255,255)",
  hooplaBackground: "rgb(255,255,255)",
  hooplaBackgroundDarker: "rgb(241,245,249)",
  hooplaBlue: "rgb(99,148,241)",
  hooplaDarkBlue: "rgb(20,58,122)",
  hooplaPurple: "rgb(222,238,247)",
  hooplaOlive: "rgb(201,208,171)",
  hooplaBeige: "rgb(233,213,172)",
  hooplaOrange: "rgb(245,195,153)",
  hooplaBorderColor: "rgb(235,235,236)"
};
export default colors;