import * as React from "react";
import { Icon } from "./styles";

const IconSearch: React.FunctionComponent = () => (
  <React.Fragment>
    <div className={Icon}>
      <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <path
            d="M10.5659 10.6507C12.5474 8.66887 12.5736 5.48195 10.6245 3.53249C8.6754 1.58303 5.48907 1.60925 3.50764 3.59106C1.5262 5.57287 1.49997 8.75979 3.44907 10.7093C5.39817 12.6587 8.5845 12.6325 10.5659 10.6507Z"
            stroke="#737476"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.1576 14.2392L10.5684 10.6493"
            stroke="#737476"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  </React.Fragment>
);

export default IconSearch;
