import { extend, media, style } from "typestyle";
import sizes from "../constants/sizes";
import breakpoints from "../constants/breakpoints";

export const Section = extend(
  {
    paddingTop: "40px",
    paddingLeft: sizes.contentPadding,
    paddingRight: sizes.contentPadding,
    paddingBottom: "100px"
  },
  media(
    { maxWidth: breakpoints.breakpointMedium },
    {
      paddingTop: "60px",
      paddingBottom: "60px",
      paddingRight: sizes.contentPaddingSmall,
      paddingLeft: sizes.contentPaddingSmall
    }
  ),
  media(
    { maxWidth: breakpoints.breakpointSmall },
    {
      paddingTop: "26px",
    }
  )
);

export const SectionTitleSmall = style({
  margin: "0 auto 20px",
  fontSize: "15px",
  fontWeight: 800,
  letterSpacing: "0.5px",
  textTransform: "uppercase",
  opacity: 0.8,
  maxWidth: "1400px"
});

export const SectionTitle = style(
  {
    fontSize: "32px",
    fontWeight: 900,
    textAlign: "center",
    marginBottom: "50px"
  },
  media(
    { maxWidth: breakpoints.breakpointMedium },
    {
      fontSize: "26px"
    }
  )
);
