import * as React from "react";
import SearchBar from "./SearchBar";

import { SearchContainer } from "./styles";
import OutsideAlerter from "../../helpers/outsideClickEvent";
import SearchIllustrationComponent from "./Illustration";
import SearchResultsContainer from "./SearchResults";
import { ApiCall } from "../../middleware/api";
import GTM from "../../gtm";

const SearchModule: React.FunctionComponent = () => {
  const [searchTerm, updateSearchTerm] = React.useState("");
  const [previousSearch, updatePrevious] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [eventResults, updateEventResults] = React.useState([]);
  const [organizationResults, updateOrganizationResults] = React.useState([]);
  const [activeSearch, setActive] = React.useState(false);
  const [offset, setOffset] = React.useState(0);
  const [allLoaded, setAllLoaded] = React.useState(false);
  const [genericError, setGenericError] = React.useState(false);
  const [searchSuggestion, setSearchSuggestion] = React.useState("");
  const [searchSuggestionClicked, setSearchSuggestionClicked] = React.useState(false);

  const limit = 8;

  function capitalize(input: string) {
    return input.charAt(0).toUpperCase() + input.slice(1);
  }

  React.useEffect(() => {
    if (searchTerm !== "") {
      setLoading(true);
      setGenericError(false);

      const newSearch = searchTerm !== previousSearch;
      if (newSearch) {
        updateEventResults([]);
        updateOrganizationResults([]);
      }
      setOffset(newSearch ? 0 : offset);

      const endpoint = `api/public/v3.0/trigramsearch?query=${encodeURIComponent(searchTerm)}&limit=${limit}&offset=${offset} `;
      let promise = ApiCall(endpoint);
      Promise.resolve(promise)
        .then((res) => {
          const events = res.event_search_hits.hits;
          const organizations = res.organization_search_hits.hits;

          GTM.dataLayer({
            dataLayer: {
              query: searchTerm,
              number_of_results: events.length + organizations.length,
              event: "search_results_returned",
            },
          });
          if (res.event_search_hits.max_score === -1 && res.organization_search_hits.max_score === -1 && res.suggested_search !== searchTerm){
            setSearchSuggestion(capitalize(res.suggested_search));
          }

          updateEventResults(newSearch ? events : eventResults.concat(events));
          updateOrganizationResults(organizations);

          setLoading(false);
          setAllLoaded(events.length < limit);
          updatePrevious(searchTerm);
        })
        .catch((error) => {
          if (error._error_type === "GenericSearchException") {
            setGenericError(true);
            setLoading(false);
          }
          console.log(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, offset]);

  return (
    <React.Fragment>
      <OutsideAlerter func={(e) => setActive(e)}>
        <div className={SearchContainer}>
          <SearchBar
            setActive={setActive}
            hasResults={searchTerm !== ""}
            updateSearchTerm={(term) => {
              updateSearchTerm(term); 
              setSearchSuggestion(""); 
              setSearchSuggestionClicked(false);
            }}
            suggestion={searchSuggestion}
            suggestionClicked={searchSuggestionClicked}
          />

          <SearchIllustrationComponent />
          {activeSearch && searchTerm !== "" && (
            <SearchResultsContainer
              allLoaded={allLoaded}
              loadMore={() => setOffset(offset + limit)}
              arrSize={limit}
              error={genericError}
              ready={!loading}
              eventResults={eventResults}
              organizationResults={organizationResults}
              searchSuggestion={searchSuggestion}
              selectSuggestion={() => {
                updateSearchTerm(searchSuggestion); 
                setSearchSuggestionClicked(true);
              }}
            />
          )}
        </div>
      </OutsideAlerter>
    </React.Fragment>
  );
};

export default SearchModule;
