import * as React from "react";

const BrandIconSmall: React.FunctionComponent = () => (
    <React.Fragment>
        <div>
            <svg width="21" height="31" viewBox="0 0 21 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 12.5597C0 11.7059 1.07506 11.3286 1.60858 11.9952L7.16988 18.9434C7.56538 19.4375 7.56612 20.1396 7.17165 20.6346L1.60977 27.6135C1.07705 28.2819 0 27.9052 0 27.0505V12.5597Z" fill="black" />
                <path d="M21 29.4151C21 30.2701 19.9225 30.6467 19.39 29.9778L8.51902 16.3239C8.12536 15.8295 8.1258 15.1286 8.52008 14.6346L19.3907 1.01615C19.9237 0.348399 21 0.725295 21 1.57969V29.4151Z" fill="black" />
            </svg>
        </div>
    </React.Fragment>
);

export default BrandIconSmall;