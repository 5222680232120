import * as React from "react";
import { Icon } from "./styles";

const IconTag: React.FunctionComponent = () => (
    <React.Fragment>
        <div className={Icon}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" height="12" width="12">
                <line x1="0.5" y1="4.25" x2="13.5" y2="4.25" fill="none" stroke="#B8B8B8" strokeLinecap="round" strokeLinejoin="round">
                </line>
                <line x1="0.5" y1="9.75" x2="13.5" y2="9.75" fill="none" stroke="#B8B8B8" strokeLinecap="round" strokeLinejoin="round">
                </line>
                <line x1="11.25" y1="0.5" x2="8.75" y2="13.5" fill="none" stroke="#B8B8B8" strokeLinecap="round" strokeLinejoin="round">
                </line>
                <line x1="5.75" y1="0.5" x2="3.25" y2="13.5" fill="none" stroke="#B8B8B8" strokeLinecap="round" strokeLinejoin="round">
                </line>
            </svg>
        </div>
    </React.Fragment>
);

export default IconTag