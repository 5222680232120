export default function assertNever(x: never): never {
  throw new Error(`Unexpected object: ${x}`);
}

declare global {
  type Environment = "production" | "staging" | "local";
  type EnvironmentVariables = {
    environment: string;
    __IMGIX_ENV__: "production" | "staging";
    __HOOPLA_GTM__: string;
    __SENTRY_DSN__: string;
    domain:  string; 
  };

  interface Window {
    ENVIRONMENT: EnvironmentVariables;
    env: Environment;
  }
}

const getCurrentVariables = (env: Environment = "local"): EnvironmentVariables => {
  switch (env) {
    case "production": {
      return {
        environment: env,
        __IMGIX_ENV__: "production",
        __HOOPLA_GTM__: "GTM-TPVQ84K",
        __SENTRY_DSN__: "https://bf83543e429a43ce80a84c0a08b5d069@o17242.ingest.sentry.io/6605203",
        domain: "hoopla.no"
      };
    }
    case "staging":
      return {
        environment: env,
        __IMGIX_ENV__: "staging",
        __HOOPLA_GTM__: "GTM-544LHK5",
        __SENTRY_DSN__: "https://59571b00e57e4e2d90a593dd8f339d62@o17242.ingest.sentry.io/6605202",
        domain: "hoopladev.no"
      };
    case "local": {
      return {
        environment: env,
        __IMGIX_ENV__: "staging",
        __HOOPLA_GTM__: "GTM-544LHK5",
        __SENTRY_DSN__: "",
        domain: "hoopladev.no"
      };
    }
    default:
      assertNever(env);
  }
};

export const setGlobalEnvironmentVariables = (env: Environment) => {
  window.ENVIRONMENT = getCurrentVariables(env);
};
