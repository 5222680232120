import React from "react";
import { MediaQuery } from "typestyle/lib/types";
import useMediaQuery from "../hooks/useMediaQuery";

const mediaQueries = {
  mobile: { maxWidth: 500 },
  tablet: { minWidth: 501, maxWidth: 800 },
  desktopSmall: { minWidth: 801, maxWidth: 1100 },
  desktopLarge: { minWidth: 1101 },
};

export const upTo = (query: MediaQuery): MediaQuery => ({ maxWidth: query.maxWidth || 99999 });
export const upFrom = (query: MediaQuery): MediaQuery => ({ minWidth: query.minWidth || 0 });
export const between = (low: MediaQuery, high: MediaQuery): MediaQuery => ({
  minWidth: low.minWidth,
  maxWidth: high.maxWidth,
});

const toSize = (size: string | number | undefined): string | undefined => {
  if (size === undefined) return undefined;
  if (typeof size === "number") return `${size}px`;
  return size;
};

export const mediaQueryToString = (mediaQuery: MediaQuery) => {
  return [
    toSize(mediaQuery.minHeight) ? `(min-height: ${toSize(mediaQuery.minHeight)})` : null,
    toSize(mediaQuery.maxHeight) ? `(max-height: ${toSize(mediaQuery.maxHeight)})` : null,
    toSize(mediaQuery.minWidth) ? `(min-width: ${toSize(mediaQuery.minWidth)})` : null,
    toSize(mediaQuery.maxWidth) ? `(max-width: ${toSize(mediaQuery.maxWidth)})` : null,
    mediaQuery.orientation ? `(orientation: ${mediaQuery.orientation})` : null,
    mediaQuery.type ? `${mediaQuery.type}` : null,
  ]
    .filter((x) => x)
    .join(" and ");
};

export const globalQueries = {
  upToMobile: mediaQueryToString(upTo(mediaQueries.mobile)),
  upToDesktopSmall: mediaQueryToString(upTo(mediaQueries.desktopSmall)),
  fromTablet: mediaQueryToString(upFrom(mediaQueries.tablet)),
  fromDesktopSmall: mediaQueryToString(upFrom(mediaQueries.desktopSmall)),
  // mobile: mediaQueryToString(mediaQueries.mobile),
  upToTablet: mediaQueryToString(upTo(mediaQueries.tablet)),
  // desktopSmall: mediaQueryToString(mediaQueries.desktopSmall),
  // desktopLarge: mediaQueryToString(mediaQueries.desktopLarge),
};

type MediaBetween = {
  between?: true;
  exact?: false;
  to?: false;
  from?: false;
  low: MediaQuery;
  high: MediaQuery;
};

type MediaExact = {
  between?: false;
  exact?: true;
  to?: false;
  from?: false;
  query: MediaQuery;
};

type MediaTo = {
  between?: false;
  exact?: false;
  to?: true;
  from?: false;
  query: MediaQuery;
};

type MediaFrom = {
  between?: false;
  exact?: false;
  to?: false;
  from?: true;
  query: MediaQuery;
};

type MediaProps = (MediaBetween | MediaExact | MediaTo | MediaFrom) & {
  children?: JSX.Element | JSX.Element[] | (() => React.ReactChild) | null;
};

export const Media: React.FC<MediaProps> = (props) => {
  let query: MediaQuery;

  if (props.between) {
    query = between(props.low, props.high);
  } else if (props.to) {
    query = upTo(props.query);
  } else if (props.from) {
    query = upFrom(props.query);
  } else if (props.exact) {
    query = props.query;
  } else {
    query = { minWidth: 0 };
  }

  const matches = useMediaQuery(query);

  if (matches) {
    return <>{typeof props.children === "function" ? props.children() : props.children}</>;
  }

  return null;
};

export default mediaQueries;
