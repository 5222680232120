import { media, style } from "typestyle";
import breakpoints from "../../constants/breakpoints";
import colors from "../../constants/colors";
import sizes from "../../constants/sizes";

export const SearchContainer = style(
  {
    position: "relative",
  },
  media(
    { maxWidth: breakpoints.breakpointMedium },
    {
      boxSizing: "border-box",
      maxWidth: "480px",
      margin: "0 auto",
    },
  ),
);

export const SearchIllustration = style(
  {
    width: "360px",
    position: "absolute",
    top: "-202px",
    right: "-80px",
  },
  media(
    {
      maxWidth: breakpoints.breakpointHero,
    },
    {
      width: "340px",
      top: "-192px",
      right: "-70px",
    },
  ),
  media(
    { maxWidth: breakpoints.breakpointMedium },
    {
      position: "relative",
      top: 0,
      right: 0,
      width: "100%",
    },
  ),
);

export const SearchIllustrationImg = style({
  display: "block",
  width: "100%",
  margin: "0 auto",
  maxWidth: "400px",
  aspectRatio: "100/57"
});

export const SearchField = style(
  {
    display: "flex",
    border: "2.5px solid " + colors.hooplaPurple,
    borderRadius: sizes.borderRadius,
    background: colors.hooplaWhite,
    alignItems: "center",
    boxSizing: "border-box",
    overflow: "hidden",
    $nest: {
      "&>[type=search]": {
        border: 0,
        flex: "1 0 auto",
        fontSize: "22px",
        padding: "18px 13px",
        background: "transparent",
        outline: "none",
      },
      /*Selector on SearchField if any of adjacent rendered elements are SearchResultContainer,
       * depends on conditional render of SearchResultContainer*/
      "[className*=SearchResultContainer]~&": {
        borderTopLeftRadius: sizes.borderRadius,
        borderTopRightRadius: sizes.borderRadius,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottom: "2.5px solid " + colors.hooplaWhite,
      },
    },
  },
  media(
    { maxWidth: breakpoints.breakpointMedium },
    {
      marginBottom: "60px",
      $nest: {
        "&>[type=search]": {
          fontSize: "19px",
        },
        "[className*=SearchResultContainer]~&": {
          marginBottom: "60px",
        },
      },
    },
  ),
  media(
    { maxWidth: breakpoints.breakpointHero },
    {
      $nest: {
        "&>[type=search]": {
          fontSize: "20px",
          paddingTop: "16px",
          paddingBottom: "16px",
        },
      },
    },
  ),
);

export const SearchInput = style({
  border: "0",
  flex: "1 0 auto",
  outline: "none",
  padding: "18px 13px",
  fontSize: "18px",
  fontFamily: "Inter",
});

export const SearchFieldIcon = style({
  flex: "0 0 16px",
  marginLeft: "17px",
  marginBottom: "3px",
});

export const SearchResultTopBorder = style({
  marginTop: "4px",
  marginLeft: "15px",
  marginRight: "15px",
  border: "1px solid " + colors.hooplaGray,
})

export const SearchResultContainer = style(
  {
    background: colors.hooplaWhite,
    boxShadow: "0 13px 13px rgba(0, 0, 0, 0.03)",
    borderBottomLeftRadius: sizes.borderRadius,
    borderBottomRightRadius: sizes.borderRadius,
    borderLeft: "2.5px solid " + colors.hooplaPurple,
    borderRight: "2.5px solid " + colors.hooplaPurple,
    borderBottom: "2.5px solid " + colors.hooplaPurple,
    display: "block",
    position: "absolute",
    left: 0,
    right: 0,
    top: "55px",
    zIndex: 1,
  },
  media(
    {
      maxWidth: breakpoints.breakpointHero,
    },
    {
      top: "55px",
    },
  ),
  media(
    {
      maxWidth: breakpoints.breakpointMedium,
    },
    {
      top: "55px",
    },
  ),
  media(
    { maxWidth: breakpoints.breakpointSmall },
    {
      top: "55px",
    },
  ),
);

export const SearchResults = style({
  padding: "8px 0",
  overflowY: "auto",
  maxHeight: "600px",
  height: "100%",
});

export const SearchResult = style({
  display: "flex",
  position: "relative",
  padding: "12px 15px",
  textDecoration: "none",
  $nest: {
    "&:focus": {
      backgroundColor: "#fafafa",
      outline: "none",
      $nest: {
        "&::before": {
          content: `""`,
          position: "absolute",
          top: "12px",
          left: 0,
          bottom: "12px",
          width: "3.5px",
          background: colors.hooplaBlue,
        },
      },
    },
    "&:hover": {
      backgroundColor: "#fafafa",
      $nest: {
        "&::before": {
          content: `""`,
          position: "absolute",
          top: "12px",
          left: 0,
          bottom: "12px",
          width: "3.5px",
          background: colors.hooplaBlue,
        },
      },
    },
  },
});

export const SearchResultTitle = style({
  fontSize: "17px",
  fontWeight: 800,
  marginBottom: "3px",
  color: colors.hooplaTextColor,
});

export const SearchResultDescription = style({
  fontSize: "13.5px",
  fontWeight: 700,
  marginBottom: "6px",
  color: colors.hooplaTextColor,
});

export const SearchResultDetails = style(
  {
    display: "flex",
  },
  media(
    { maxWidth: breakpoints.breakpointSmall },
    {
      flexDirection: "column",
    },
  ),
);

export const SearchResultDetail = style(
  {
    display: "flex",
    $nest: {
      "&:not(:last-child)": {
        marginRight: "9px",
      },
      "&>p": {
        marginTop: "1.5px",
        fontSize: "13px",
        color: colors.hooplaTextColor120,
      },
    },
  },
  media(
    { maxWidth: breakpoints.breakpointSmall },
    {
      $nest: {
        "& :not(:last-child)": {
          marginBottom: "4px",
          marginRight: 0,
        },
      },
    },
  ),
);

export const SearchResultDate = style({
  marginTop: "1.5px",
  fontSize: "13px",
  color: colors.hooplaTextColor120,
});

export const SearchResultDetailIcon = style({
  marginRight: "4px",
});

export const ResultImage = style({
  flex: "0 0 60px",
  width: "60px",
  height: "45px",
  borderRadius: "4px",
  overflow: "hidden",
  marginRight: "15px",
});

export const ResultImageImg = style({
  display: "block",
  width: "100%",
});

export const ResultInformation = style({
  marginTop: "-3px",
});

export const NoResults = style({
  fontSize: "18px",
  padding: "16px",
  display: "flex",
  flexDirection: "column",
  $nest: {
    "&>span:last-child": {
      fontSize: "14px",
      marginTop: "8px",
      $nest: {
        "&>span": {
          textDecoration: "underline",
          cursor: "pointer"
        }
      }
    }
  }
});

export const LoadMore = style({
  justifyContent: "center",
  display: "flex",
  position: "relative",
  padding: "12px 15px",
  textDecoration: "none",
});
