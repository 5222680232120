import * as React from "react";
import { IconPromoConfetti } from "./styles";

const IconPromoConfetti2: React.FunctionComponent = () => (
  <React.Fragment>
    <div className={IconPromoConfetti}>
      <svg viewBox="0 0 36 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.38 15.08l5.6 4.09a1 1 0 01-.35 1.78l-5.96 1.53a1 1 0 01-1.25-1.03l.37-5.63a1 1 0 011.59-.74z"
          fill="#fff"
        />
        <path
          d="M17.95 1.87l2.27 4.33a1 1 0 01-1.1 1.45l-4.67-1.02a1 1 0 01-.6-1.57l2.4-3.3a1 1 0 011.7.11z"
          fill="#13356E"
        />
        <path
          d="M35.57 22.2l-3.08-9.35a1 1 0 00-1.75-.28l-5.71 7.56a1 1 0 00.6 1.58l8.8 1.79a1 1 0 001.14-1.3z"
          fill="#FAD67A"
        />
      </svg>
    </div>
  </React.Fragment>
);

export default IconPromoConfetti2;
