import * as React from "react";

const SadNacho: React.FunctionComponent = () => (
    <React.Fragment>
        <div style={{ display: "block" }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 52 56" width="52" height="56">
                <path fill="#AFD4FF" d="M50.3351 48.414C50.2443 51.5302 46.7817 53.3493 44.1642 51.6561L13.5269 31.837C11.0149 30.212 11.1107 26.5053 13.7032 25.0122L45.4507 6.72783C48.1624 5.16607 51.5365 7.18259 51.4453 10.3106L50.3351 48.414Z" />
                <g opacity=".09">
                    <mask id="a" width="41" height="47" x="11" y="6" maskUnits="userSpaceOnUse">
                        <path fill="#AFD4FF" d="M50.2019 48.4097C50.102 51.5256 46.634 53.3346 44.0215 51.6336L13.4426 31.7246C10.9354 30.0922 11.042 26.3859 13.639 24.9004L45.4401 6.70936C48.1563 5.15558 51.5244 7.182 51.4241 10.3097L50.2019 48.4097Z" />
                    </mask>
                    <g mask="url(#a)">
                        <path fill="url(#pattern0)" d="M0 0H36.5279V59.2465H0z" transform="matrix(-0.946724 0.322047 0.322047 0.946724 57.3403 -3.30127)" />
                    </g>
                </g>
                <path stroke="#181351" stroke-linecap="round" stroke-width="2.70812" d="M42.5948 35.7026C41.2031 34.3261 39.4613 33.6347 37.7432 33.9713C36.0251 34.308 34.673 35.6056 33.9037 37.4054" />
                <circle cx="4.91321" cy="2.3021" r="1.875" fill="#FFCFD5" transform="rotate(4.43918 4.91321 2.3021)" />
                <circle cx="10.2882" cy="7.35825" r="1.875" fill="#FFCFD5" transform="rotate(4.43918 10.2882 7.35825)" />
                <circle cx="15.6627" cy="12.4144" r="1.875" fill="#FFCFD5" transform="rotate(4.43918 15.6627 12.4144)" />
                <circle cx="4.17347" cy="11.8329" r="1.875" fill="#FFCFD5" transform="rotate(4.43918 4.17347 11.8329)" />
                <circle cx="9.54798" cy="16.889" r="1.875" fill="#FFCFD5" transform="rotate(4.43918 9.54798 16.889)" />
                <circle cx="3.43323" cy="21.3636" r="1.875" fill="#FFCFD5" transform="rotate(4.43918 3.43323 21.3636)" />
                <circle cx="2.69349" cy="30.8949" r="1.875" fill="#FFCFD5" transform="rotate(4.43918 2.69349 30.8949)" />
                <path fill="#70B2FF" d="M19.8361 45.1889C20.5926 46.2928 21.7567 47.051 23.0722 47.2966C24.3878 47.5423 25.747 47.2552 26.8509 46.4987C27.9548 45.7422 28.713 44.5781 28.9586 43.2626C29.3795 41.0087 27.0042 35.124 26.149 33.1061C26.1168 33.0293 26.066 32.9618 26.0012 32.9095C25.9364 32.8572 25.8597 32.8218 25.7779 32.8066C25.696 32.7913 25.6117 32.7966 25.5324 32.8219C25.4531 32.8473 25.3814 32.892 25.3236 32.952C23.7959 34.5249 19.459 39.1563 19.0382 41.4102C18.7925 42.7257 19.0796 44.085 19.8361 45.1889Z" />
                <circle cx="43.3923" cy="24.7944" r="2.1665" fill="#181351" />
                <circle cx="28.0325" cy="28.1777" r="2.1665" fill="#181351" />
                <defs>
                    <pattern id="pattern0" width="1" height="1" patternContentUnits="objectBoundingBox">
                        <use transform="scale(0.00609756 0.0037594)" />
                    </pattern>
                </defs>
            </svg>
        </div>

    </React.Fragment >
);

export default SadNacho;
