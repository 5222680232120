import * as React from "react";
import { SearchIllustration, SearchIllustrationImg } from "./styles";

const SearchIllustrationComponent: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <div className={SearchIllustration}>
        <picture>
          <source
            type={"image/webp"}
            srcSet={`${require("../../assets/Images/search_illustration@1x.webp")} 1x, 
                     ${require("../../assets/Images/search_illustration@2x.webp")} 2x,
                     ${require("../../assets/Images/search_illustration@3x.webp")} 3x`}
          />
          <source
            type={"image/png"}
            srcSet={`${require("../../assets/Images/search_illustration@1x.png")} 1x, 
                     ${require("../../assets/Images/search_illustration@2x.png")} 2x,
                     ${require("../../assets/Images/search_illustration@3x.png")} 3x`}
          />
          <img
            className={SearchIllustrationImg}
            src={require("../../assets/Images/search_illustration@1x.png")}
            alt={"Illustrasjon"}
          />
        </picture>
      </div>
    </React.Fragment>
  );
};

export default SearchIllustrationComponent;
