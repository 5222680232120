import * as React from "react";
import {
  LoadMore,
  NoResults,
  SearchResultContainer,
  SearchResults,
  SearchResultTopBorder,
} from "./styles";
import { EventResultsProps, OrganizationResultsprops } from "../../helpers/props";
import { SkeletonTransition } from "../../components/Skeleton/skeletonTransition";
import SearchResultEvent from "./SearchResultEvent";
import SearchResultSkeleton from "./SearchResultSkeleton";
import SearchResultOrganizer from "./SearchResultOrganizer";
import { LoadMoreButton } from "../../components/Button";
import { FormattedMessage } from "react-intl";

const SearchResultsContainer: React.FunctionComponent<
  EventResultsProps &
    OrganizationResultsprops & {
      ready: boolean;
      arrSize: number;
      loadMore: () => void;
      allLoaded: boolean;
      error: boolean;
      searchSuggestion: string;
      selectSuggestion: () => void;
    }
> = (props) => {
  return (
    <React.Fragment>
      <div className={SearchResultContainer}>
        <div className={SearchResultTopBorder}></div>
        <SkeletonTransition
          ready={props.ready || props.eventResults.length > 0}
          placeholder={<SearchResultSkeleton arrSize={props.arrSize} />}
        >
          {props.error ? (
            <div style={{ textAlign: "center", margin: "16px auto" }}>
              <FormattedMessage
                id="containers.Search.SearchResults.genericError"
                defaultMessage="Something went wrong with your search. Please try again."
              />
            </div>
          ) : [...props.organizationResults, ...props.eventResults].length === 0 ? (
            <div className={NoResults}>
              <FormattedMessage
                id="containers.Search.SearchResults.noHits"
                defaultMessage="Ingen treff på søket ditt"
                tagName={"span"}
              />
              {props.searchSuggestion !== "" && 
                <FormattedMessage
                  id="containers.Search.SearchResults.didYouMean"
                  defaultMessage="Did you mean <span>{suggestion}</span>?"
                  tagName={"span"}
                  values={{
                    span: (chunks: React.ReactNode) => <span onClick={() => props.selectSuggestion()}>{chunks}</span>,
                    suggestion: props.searchSuggestion
                  }}
                />
              }
            </div>
          ) : (
            <div className={SearchResults}>
              {props.organizationResults.map((it) => (
                <SearchResultOrganizer key={it.organization.identifier} {...it} />
              ))}
              {props.eventResults.map((it) => (
                <SearchResultEvent key={it.event.event_id} {...it} />
              ))}
            </div>
          )}
        </SkeletonTransition>
        {!props.allLoaded && (
          <SkeletonTransition
            ready={props.ready || props.eventResults.length === 0}
            placeholder={<SearchResultSkeleton arrSize={props.arrSize} />}
          >
            <div className={LoadMore}>
              <LoadMoreButton updateEvents={props.loadMore} />
            </div>
          </SkeletonTransition>
        )}
      </div>
    </React.Fragment>
  );
};

export default SearchResultsContainer;
