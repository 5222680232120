import format from "date-fns/format";
import React from "react";
import { FormattedMessage } from "react-intl";
import prettyDate from "./prettydate";

type FormattedDateHelperProps = {
  start: Date;
  end: Date;
  dateFormat?: string;
  text?: string;
};

const FormattedDateHelper: React.FC<FormattedDateHelperProps> = (props) => {
  const [start] = React.useState(props.start);
  const [end] = React.useState(props.end);
  const [dateFormat] = React.useState(props.dateFormat || "start_date");
  const [text] = React.useState(props.text || "");

  return (
    <>
      {dateFormat === "start_end_date_and_time" && prettyDate(start) !== prettyDate(end) && (
        <React.Fragment>
          <div style={{ display: "flex", alignItems: "center" }}>{prettyDate(start)}
            {format(start, ", HH:mm ")}<FormattedMessage id="containers.Helpers.to" defaultMessage="til " />{prettyDate(end)}</div>
        </React.Fragment>
      )}
      {dateFormat === "start_end_date_and_time" && prettyDate(start) === prettyDate(end) && (
        <React.Fragment>
          <div style={{ display: "flex", alignItems: "center" }}>{prettyDate(start)}
            {format(start, ", HH:mm")}{format(end, "HH:mm")}</div>
        </React.Fragment>
      )}
      {dateFormat === "start_date_and_time" && (
        <React.Fragment>
          {prettyDate(start)}
          {format(start, ", HH:mm")}
        </React.Fragment>
      )}
      {dateFormat === "start_date" && (
        <React.Fragment>
          {prettyDate(start)}
        </React.Fragment>
      )}
      {dateFormat === "start_end_date" && prettyDate(start) !== prettyDate(end) && (
        <React.Fragment>

          <div style={{ display: "flex", alignItems: "center" }}>{prettyDate(start)} <FormattedMessage id="containers.Helpers.to" defaultMessage="til " />{prettyDate(end)}</div>
        </React.Fragment>
      )}
      {dateFormat === "start_end_date" && prettyDate(start) === prettyDate(end) && (
        <React.Fragment>
          {prettyDate(start)}
        </React.Fragment>
      )}
      {dateFormat === "free_text" && (
        <React.Fragment>
          {text}
        </React.Fragment>
      )}
    </>
  );
};

export default FormattedDateHelper;

