import * as React from "react";
import {
  ResultImage,
  ResultInformation,
  SearchResult,
  SearchResultDetail,
  SearchResultDetails,
  SearchResults,
  SearchResultTitle
} from "./styles";
import { TextRow } from "react-placeholder/lib/placeholders";
import { SkeletonSvg } from "../../assets/partials/skeletonSvg";
import { classes } from "typestyle";
import { LoadingAnimation } from "../../components/Skeleton/styles";

const SearchResultSkeleton: React.FunctionComponent<{
  arrSize: number;
}> = props => (
  <React.Fragment>
    <div className={SearchResults}>
      {Array(props.arrSize)
        .fill(0)
        .map((_, index) => (
          <div
            key={"searchResult" + index}
            className={classes(SearchResult, LoadingAnimation)}
          >
            <div className={ResultImage}>
              <SkeletonSvg crop={{ width: 60, height: 45 }} />
            </div>
            <div className={ResultInformation} style={{ flex: "1 0" }}>
              <div className={SearchResultTitle}>
                <TextRow color={"lightgray"} style={{ marginTop: "0.2em" }} />
              </div>
              <div className={SearchResultDetails}>
                <div
                  className={SearchResultDetail}
                  style={{ flex: "1 0", fontSize: "12px" }}
                >
                  <TextRow color={"lightgray"} />
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  </React.Fragment>
);

export default SearchResultSkeleton;
