import React from "react";
import "./index.css";
import App from "./App/App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { injectGlobals } from "./styles";
import { setGlobalEnvironmentVariables } from "./environment";
import { createRoot } from "react-dom/client";
import * as gtm from "./gtm";

setGlobalEnvironmentVariables(process.env.REACT_APP_ENVIRONMENT as Environment);

injectGlobals();

Sentry.init({
  dsn: window.ENVIRONMENT.__SENTRY_DSN__,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
  ignoreErrors: ['Non-Error promise rejection captured']
});

const container = document.getElementById("root");
const root = createRoot(container!);

gtm.initialize();

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
