import * as React from "react";
import { OrganizerImage } from "./styles";
import { OrganizerImageProps } from "../../helpers/props";
import { organizerCrop } from "../../constants/crops";
import LazyImageRenderer from "../../components/LazyImageRenderer";
import sizes from "../../constants/sizes";
import { newImageCrop } from "../../helpers/newImageCrop";

const OrganizerImageModule: React.FunctionComponent<OrganizerImageProps> = (props) => {
  const imageCrop = newImageCrop(props.imageUrl, props.imageCrop);
  return (
    <React.Fragment>
      <div className={OrganizerImage}>
        <LazyImageRenderer
          imgixImage={imageCrop}
          croppedImages={props.images}
          base={{
            width: 327,
            ar: "Square",
          }}
          style={{
            display: "block",
            width: "100%",
            borderRadius: sizes.borderRadius,
            overflow: "hidden",
            aspectRatio: "1",
          }}
          queries={
            [
              {
                type: "MinWidth",
                minWidth: 1100,
                size: {
                  width: 327,
                  ar: "Square",
                },
                style: {
                  display: "block",
                  width: "100%",
                  borderRadius: sizes.borderRadius,
                  overflow: "hidden",
                  aspectRatio: "1",
                },
              },
              {
                type: "MinWidth",
                minWidth: 420,
                size: {
                  width: 290,
                  ar: "Square",
                },
                style: {
                  display: "block",
                  width: "100%",
                  borderRadius: sizes.borderRadius,
                  overflow: "hidden",
                  aspectRatio: "1",
                },
              },
              {
                type: "MinWidth",
                minWidth: 1,
                size: {
                  width: 200,
                  ar: "Square"
                },
                style: {
                  display: "block",
                  width: "100%",
                  borderRadius: sizes.borderRadius,
                  overflow: "hidden",
                  aspectRatio: "1",
                },
              }
            ]}
          alt={props.organizerName}
          missingImageClass={props.className}
          missingImageCrop={organizerCrop}
        />
      </div>
    </React.Fragment>
  );
};

export default OrganizerImageModule;
