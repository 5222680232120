import * as React from "react";
import { IconPromoConfetti } from "./styles";

const IconPromoConfetti1: React.FunctionComponent = () => (
  <React.Fragment>
    <div className={IconPromoConfetti}>
      <svg viewBox="0 0 36 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M32.59.145l-5.505 1.567a1 1 0 00-.478 1.62l4.394 5.012a1 1 0 001.738-.493l1.11-6.578A1 1 0 0032.59.145z"
          fill="#FAD67A"
        />
        <path
          d="M27.032 18.99l3.752 3.135a1 1 0 01-.465 1.752l-4.707.842a1 1 0 01-1.149-1.218l.955-3.977a1 1 0 011.614-.534z"
          fill="#13356E"
        />
        <path
          d="M2.129 10.115l5.736-6.684a1 1 0 011.729.408l2.05 8.143a1 1 0 01-1.154 1.227l-7.787-1.46a1 1 0 01-.574-1.634z"
          fill="#fff"
        />
      </svg>
    </div>
  </React.Fragment>
);

export default IconPromoConfetti1;
