import * as React from "react";
import {
  PromoSectionIllustration,
  PromoSectionIllustrationImg
} from "./styles";

const PromoImage: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <div className={PromoSectionIllustration}>
        <picture>
          <source
            type={"image/webp"}
            srcSet={`${require("../../assets/Images/lag-en-salgsside@1x.webp")} 1x, 
                     ${require("../../assets/Images/lag-en-salgsside@2x.webp")} 2x`}
          />
          <source
            type={"image/png"}
            srcSet={`${require("../../assets/Images/lag-en-salgsside@1x.png")} 1x, 
                     ${require("../../assets/Images/lag-en-salgsside@2x.png")} 2x`}
          />
          <img
            className={PromoSectionIllustrationImg}
            src={require("../../assets/Images/lag-en-salgsside@1x.png")}
            alt={"Promo"}
          />
        </picture>
      </div>
    </React.Fragment>
  );
};

export default PromoImage;
