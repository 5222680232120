export const newImageCrop = (url: string | null, obj: any) =>
  obj
    ? {
        url: url ? url : "",
        crop: {
          height: obj["height"],
          width: obj["width"],
          offsetX: obj["x"] ? obj["x"] : 0,
          offsetY: obj["Y"] ? obj["y"] : 0,
        },
      }
    : null;
