import * as React from "react";
import { PromoMessage } from "./styles";
import { FormattedMessage } from "react-intl";
import IconPromoConfetti1 from "../../assets/partials/icon_promo_confetti_1";
import IconPromoConfetti2 from "../../assets/partials/icon_promo_confetti_2";
import { PromoMessageInline } from "./styles";
import { promoMessageLink } from "../../constants/links";

const PromoMessageContainer: React.FunctionComponent = () => {
  const promoMessage = promoMessageLink()
  return (
    <React.Fragment>
      <a className={PromoMessage} href={promoMessage} target={"_blank"} rel="noopener noreferrer">
      <IconPromoConfetti1 />
      <div className={PromoMessageInline}>
        <strong>
          <FormattedMessage id="containers.PromoMessage.organize" defaultMessage="Arrangere noe?" />
        </strong>
        <FormattedMessage id="containers.PromoMessage.sell" defaultMessage="Selg billetter med Hoopla!" />

      </div>
      <IconPromoConfetti2 />
      </a>
    </React.Fragment>
  );
};

export default PromoMessageContainer;
