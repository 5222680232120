import * as React from "react";
import { Icon } from "./styles";

const IconDate: React.FunctionComponent = () => (
  <React.Fragment>
    <div className={Icon}>
      <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8.00074" cy="8" r="5.44605" fill="#C7C7C7" />
        <path
          d="M7.82617 4.81982V7.88452L9.7416 9.46475"
          stroke="white"
          strokeWidth="1.16701"
          strokeLinecap="round"
        />
      </svg>
    </div>
  </React.Fragment>
);

export default IconDate;
