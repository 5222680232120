import { fontFace } from "typestyle";

const includeVariableFontFace = (
  family: string,
  weightFrom: number,
  weightTo: number,
  baseFileName: string,
  unicodeRange?: string
) =>
  fontFace({
    fontFamily: family,
    fontWeight: `${weightFrom} ${weightTo}`,
    fontDisplay: "swap",
    src: `url(${require(`../assets/Fonts/Inter/${baseFileName}.woff2`)}) format("woff2")`,
    unicodeRange
  });

const includeFontFace = (
  family: string,
  style: "normal" | "italic",
  weight: number,
  baseFileName: string,
  unicodeRange?: string
) =>
  fontFace({
    fontFamily: family,
    fontStyle: style,
    fontWeight: weight,
    fontDisplay: "swap",
    src: `url(${require(`../assets/Fonts/Inter/${baseFileName}.woff`)}) format("woff"),
          url(${require(`../assets/Fonts/Inter/${baseFileName}.woff2`)}) format("woff2")`,
    unicodeRange
  });

const range = "U+0020-007F, U+00A0-00FF, U+0100-017F";

includeVariableFontFace("Inter V", 1, 999, "Inter-V.var.subset", range);

includeFontFace("Inter", "normal", 100, "Inter-Thin", range);
includeFontFace("Inter", "normal", 200, "Inter-ExtraLight", range);
includeFontFace("Inter", "normal", 300, "Inter-Light", range);
includeFontFace("Inter", "normal", 400, "Inter-Regular", range);
includeFontFace("Inter", "normal", 500, "Inter-Medium", range);
includeFontFace("Inter", "normal", 600, "Inter-SemiBold", range);
includeFontFace("Inter", "normal", 700, "Inter-Bold", range);
includeFontFace("Inter", "normal", 800, "Inter-ExtraBold", range);
includeFontFace("Inter", "normal", 900, "Inter-Black", range);

includeFontFace("Inter", "italic", 100, "Inter-ThinItalic", range);
includeFontFace("Inter", "italic", 200, "Inter-ExtraLightItalic", range);
includeFontFace("Inter", "italic", 300, "Inter-LightItalic", range);
includeFontFace("Inter", "italic", 400, "Inter-Italic", range);
includeFontFace("Inter", "italic", 500, "Inter-MediumItalic", range);
includeFontFace("Inter", "italic", 600, "Inter-SemiBoldItalic", range);
includeFontFace("Inter", "italic", 700, "Inter-BoldItalic", range);
includeFontFace("Inter", "italic", 800, "Inter-ExtraBoldItalic", range);
includeFontFace("Inter", "italic", 900, "Inter-BlackItalic", range);
