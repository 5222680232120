import {
  differenceInCalendarDays,
  format,
  getHours,
  isBefore,
  isSameDay,
  isSameYear,
} from "date-fns";

import { enUS, nb } from "date-fns/locale";
import * as storage from "./storage";


const formats = {
  todayDateMonth: "'Today', d. LLL", // Today, 1. jan
  todayDayDateMonth: "'Today', eee d. LLL", // Today, 1. jan
  tomorrowDateMonth: "'Tomorrow', d. LLL", // Tomorrow, 1. jan
  dayDateMonth: "eeee d. LLL", // Monday 1. jan
  dayDateMonthYear: "eeee d. LLL yyyy", // Monday 1. jan 2019
};

// https://github.com/date-fns/date-fns/issues/1218
// issue open for native locale support, not resolved
const formatsNo = {
  todayDateMonth: "'I dag', d. LLL", // Today, 1. jan
  todayDayDateMonth: "'I dag', eee d. LLL", // Today, 1. jan
  tomorrowDateMonth: "'I morgen', d. LLL", // Tomorrow, 1. jan
  dayDateMonth: "eeee d. LLL", // Monday 1. jan
  dayDateMonthYear: "eeee d. LLL yyyy", // Monday 1. jan 2019
};

export const getLocale = (locale: string): Locale => {
  switch (locale) {
    case "en-US": {
      return enUS;
    }
    case "nb-NO": {
      return nb;
    }
    default: {
      return nb;
    }
  }
};

export const getFormat = (d: Date, baseDate = new Date(), lang = "nb-NO") => {
  if (isBefore(d, baseDate)) {
    return formats.dayDateMonthYear;
  } else if (isSameDay(baseDate, d)) {
    if (getHours(baseDate) <= 5 && getHours(baseDate) >= 0) {
      return lang === "en-US" ? formats.todayDayDateMonth : formatsNo.todayDayDateMonth;
    } else {
      return lang === "en-US" ? formats.todayDateMonth : formatsNo.todayDateMonth;
    }
  } else if (differenceInCalendarDays(d, baseDate) === 1) {
    return lang === "en-US" ? formats.tomorrowDateMonth : formatsNo.tomorrowDateMonth;
  } else if (isSameYear(d, baseDate)) {
    return formats.dayDateMonth;
  }

  return formats.dayDateMonthYear;
};

const prettyDate = (d: Date, baseDate = new Date()) => {
  const lang = storage.getItem("lang", "nb-NO");
  const formatString = format(d, getFormat(d, baseDate, lang), { locale: getLocale(lang) });
  return formatString.charAt(0).toUpperCase() + formatString.slice(1);
};

export default prettyDate;
