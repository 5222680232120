import * as React from "react";
import {
  ResultImage,
  ResultInformation,
  SearchResult,
  SearchResultDetail,
  SearchResultDetailIcon,
  SearchResultDetails,
  SearchResultTitle,
  ResultImageImg,
} from "./styles";
import IconVenue from "../../assets/partials/icon_venue";
import IconOrganizerSearch from "../../assets/partials/icon_organizer_search";
import { OrganizationResultProps } from "../../helpers/props";
import { searchCrop } from "../../constants/crops";
import { searchLink } from "../../constants/links";
import LazyImageRenderer from "../../components/LazyImageRenderer";
import { FormattedMessage } from "react-intl";
import { newImageCrop } from "../../helpers/newImageCrop";

const SearchResultOrganizer: React.FunctionComponent<OrganizationResultProps> = (props) => {
  const imageCrop = newImageCrop(
    props.organization.data?.image || null,
    props.organization.data?.image_crop,
  );
  return (
    <React.Fragment>
      <a href={searchLink(props.organization.identifier)} className={SearchResult}>
        <div className={ResultImage}>
          <LazyImageRenderer
            imgixImage={imageCrop}
            croppedImages={props.organization.images}
            base={{
              width: 65,
              ar: "FourByThree",
            }}
            style={{ display: "block", width: "100%", aspectRatio: "4/3" }}
            queries={[]}
            alt={props.organization.name}
            missingImageCrop={searchCrop}
            missingImageClass={ResultImageImg}
          />
        </div>
        <div className={ResultInformation}>
          <div className={SearchResultTitle}>{props.organization.name}</div>
          <div className={SearchResultDetails}>
            {props.organization.data?.location?.city ? (
              <div className={SearchResultDetail}>
                <div className={SearchResultDetailIcon}>
                  <IconVenue />
                </div>
                <p>{props.organization.data.location.city}</p>
              </div>
            ) : null}
            <div className={SearchResultDetail}>
              <div className={SearchResultDetailIcon}>
                <IconOrganizerSearch />
              </div>
              <p>
                {" "}
                {props.number_of_published_future_events}{" "}
                <FormattedMessage
                  id="containers.Search.SearchResults.events"
                  defaultMessage="arrangementer"
                />{" "}
              </p>
            </div>
          </div>
        </div>
      </a>
    </React.Fragment>
  );
};

export default SearchResultOrganizer;
