import * as React from "react";
import { EventProps } from "../../helpers/props";
import {
  EventDescription,
  EventImage,
  EventImageImg,
  Eventmod3,
  EventNotMod3,
  EventTitle,
  EventTitleAndDetails,
} from "./styles";
import { placeholderAnimation } from "../../components/Skeleton/styles";
import EventDetails from "./EventDetails";
import EventImageModule from "./EventImageModule";
import { redirLink } from "../../constants/links";

export const EventModule: React.FunctionComponent<EventProps & { eventmod3: boolean }> = (
  props,
) => {
  return (
    <React.Fragment>
      <a
        href={redirLink(
          props.organization.identifier,
          props.event.identifier ? props.event.identifier : props.event.event_id,
        )}
        className={props.eventmod3 ? Eventmod3 : EventNotMod3}
        style={placeholderAnimation["entered"]}
      >
        <div className={EventImage}>
          <EventImageModule
            name={props.event.name}
            url={props.event.data.image}
            imageCrop={props.event.data.image_crop}
            images={props.event.images}
            className={EventImageImg}
          />
        </div>
        <div className={EventTitleAndDetails}>
          <p className={EventTitle}>{props.event.name}</p>
          {props.event.short_description && (
            <p className={EventDescription}>{props.event.short_description}</p>
          )}
          <EventDetails
            start={new Date(props.event.start)}
            end={new Date(props.event.end)}
            dateFormat={props.event.date_formats}
            location={props.event.data.location}
            category={
              props.event.data.category === "OTHER" && props.event.data.other_category_description
                ? props.event.data.other_category_description
                : props.event.data.category
            }
          />
        </div>
      </a>
    </React.Fragment>
  );
};

export default EventModule;
