import {
  EventDetail,
  EventImage,
  EventImageImg,
  Eventmod3,
  Events,
  EventTitle,
  EventTitleAndDetails
} from "./styles";
import * as React from "react";
import { TextRow } from "react-placeholder/lib/placeholders";
import { classes } from "typestyle";
import { LoadingAnimation } from "../../components/Skeleton/styles";
import { eventCrop } from "../../constants/crops";
import { SkeletonSvg } from "../../assets/partials/skeletonSvg";

const EventSkeleton: React.FunctionComponent<{
  arrSize: number;
}> = props => (
  <React.Fragment>
    <div className={Events}>
      {Array(props.arrSize)
        .fill(0)
        .map((_, index) => (
          <div
            key={"event" + index}
            className={classes(LoadingAnimation, Eventmod3)}
          >
            <div className={EventImage}>
              <SkeletonSvg crop={eventCrop} className={EventImageImg} />
            </div>
            <div
              className={EventTitleAndDetails}
              style={{ marginTop: "-8px", flex: "0 0 100%" }}
            >
              <div className={EventTitle}>
                <TextRow color={"lightGray"} />
              </div>
              <div className={EventDetail}>
                <TextRow color={"lightGray"} />
              </div>
              <div className={EventDetail}>
                <TextRow color={"lightGray"} />
              </div>
            </div>
          </div>
        ))}
    </div>
  </React.Fragment>
);

export default EventSkeleton;
