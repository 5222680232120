import * as React from "react";
import {
  Organizer,
  OrganizerDetails,
  OrganizerImageImg,
  OrganizerTitle,
  OrganizerTitleAndDetails,
} from "./styles";
import { OrganizationModuleProps } from "../../helpers/props";
import OrganizerImageModule from "./OrganizerImage";
import { redirLink } from "../../constants/links";
import { useIntl } from "react-intl";

const OrganizerModule: React.FunctionComponent<OrganizationModuleProps> = (props) => {
  const intl = useIntl();

  const buildDetails = (city: string | null, futureEvents: number) => {
    if (city) {
      return `${city} \u00B7 ${intl.formatMessage(
        {
          id: "containers.OrganizerList.events",
          defaultMessage: "{events, plural, =0 {0 events} one {1 event} other {# events}}",
        },
        { events: futureEvents },
      )}`;
    } else {
      return intl.formatMessage(
        {
          id: "containers.OrganizerList.events",
          defaultMessage: "{events, plural, =0 {0 events} one {1 event} other {# events}}",
        },
        { events: futureEvents },
      );
    }
  };
  return (
    <React.Fragment>
      <a href={redirLink(props.organization.identifier)} className={Organizer}>
        <OrganizerImageModule
          className={OrganizerImageImg}
          organizerName={props.organization.name}
          imageUrl={props.organization.data ? props.organization.data.image : null}
          imageCrop={props.organization.data ? props.organization.data.image_crop : null}
          images={props.organization.images}
        />
        <div className={OrganizerTitleAndDetails}>
          <span className={OrganizerTitle}>{props.organization.name}</span>
          <div style={{ display: "flex" }}>
            <span className={OrganizerDetails}>
              {buildDetails(
                props.organization.data?.location.city,
                props.number_of_published_future_events,
              )}
            </span>
          </div>
        </div>
      </a>
    </React.Fragment>
  );
};

export default OrganizerModule;
