import * as React from "react";
import Flags from "country-flag-icons/react/3x2";
import { AppHeader, AppHeaderInner, AppHeaderNavigation } from "./styles";
import HooplaLogo from "../../components/HooplaLogo";
import HooplaLogoSmall from "../../components/HooplaLogoSmall"
import { OrganizerButton } from "./OrganizerButton";
import MyPageButton from "./MyPageButton";
import { FormattedMessage } from "react-intl";
import { classes } from "typestyle";
import useOutsideClick from "../../hooks/useOutsideClick";
import { ReactComponent as Globe } from "../../assets/Illustrations/globe.svg";
import * as styles from "./styles";
import ShadowBox from "../../components/ShadowBox";
import mediaQueries, { Media } from "../../styles/mediaQueries";

type HeaderProps = {
  onChangeLanguage: (lang: string) => void;
  currentLanguage: string;
};

const possibleLanguages = [
  {
    name: "nb-NO",
    flag: <Flags.NO title="Norwegian" viewBox="30 85 340 340" />,
    text: <FormattedMessage id={"components.Header.norwegian"} defaultMessage={"Norwegian"} />,
  },
  {
    name: "en-US",
    flag: <Flags.GB title="English" viewBox="90 85 340 340" />,
    text: <FormattedMessage id={"components.Header.english"} defaultMessage={"English"} />,
  },
];

const Header: React.FC<HeaderProps> = ({ onChangeLanguage, currentLanguage }) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const menuTogglerRef = React.useRef(null);

  useOutsideClick(menuTogglerRef, () => setMenuOpen(false));

  return (
    <React.Fragment>
      <header className={AppHeader}>
        <div className={AppHeaderInner}>
          <Media from query={mediaQueries.tablet}>
            <HooplaLogo />
          </Media>
          <Media to query={mediaQueries.mobile}>
            <HooplaLogoSmall />
          </Media>
          <div className={AppHeaderNavigation}>
            <OrganizerButton />
            <MyPageButton />
            <div ref={menuTogglerRef} style={{ position: "relative" }}>
              <button
                className={styles.AppHeaderNavigationItem}
                style={{ position: "relative" }}

                onClick={() => setMenuOpen((open) => !open)}
              >
                <div className={styles.AppHeaderButtonText} style={{ paddingRight: "5px" }}>
                  <Globe />
                </div>
                <span className={styles.AppHeaderButtonText}>
                  <FormattedMessage id="components.Header.language" defaultMessage="Language" />
                </span>
              </button>
              <ShadowBox className={classes(styles.menu, menuOpen && styles.menuOpen)}>
                <React.Fragment>
                  {possibleLanguages.map((l) => (
                    <button
                      key={l.name}
                      className={styles.menuItem}
                      onClick={() => {
                        onChangeLanguage(l.name);
                        setMenuOpen(false);
                      }}
                    >
                      <div className={styles.flag}>{l.flag}</div>
                      <div>{l.text}</div>
                    </button>
                  ))}
                </React.Fragment>
              </ShadowBox>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
