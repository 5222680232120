import * as React from "react";
import { PaymentCardSvg } from "./styles";

const IconBankaxept: React.FunctionComponent = () => (
  <React.Fragment>
    <svg
      className={PaymentCardSvg}
      width="39"
      height="25"
      viewBox="0 0 39 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.75972 10.3082C6.22636 10.3082 5.84872 10.2154 5.63812 10.0904L5.63086 5.97032L6.52572 5.9679V7.3816C6.70533 7.30108 6.90114 7.26324 7.09781 7.27105C7.82403 7.27105 8.33238 7.82459 8.334 8.69282C8.34045 9.72324 7.774 10.3034 6.75972 10.3082ZM6.84848 7.75681C6.73702 7.75115 6.62596 7.77392 6.52572 7.82297L6.53056 9.74986C6.61873 9.78036 6.71164 9.79484 6.80491 9.79263C7.25758 9.79263 7.45043 9.40854 7.45043 8.7493C7.45366 8.17559 7.32617 7.75519 6.84848 7.75681Z"
        fill="#5A2C78"
      />
      <path
        d="M10.0044 10.2979C9.10952 10.3003 8.652 9.9856 8.65039 9.37316C8.65039 8.61466 9.44439 8.38873 10.3723 8.304V8.16764C10.3723 7.86182 10.1529 7.77548 9.82686 7.77629C9.55017 7.78416 9.27769 7.84612 9.0248 7.95865L8.82872 7.47451C9.19808 7.32473 9.5929 7.24774 9.99147 7.24776C10.6902 7.24212 11.2075 7.52131 11.2075 8.31853L11.2123 10.076C10.9557 10.2075 10.5377 10.2963 10.0044 10.2979ZM10.3731 8.69455C9.83412 8.74538 9.48554 8.86642 9.48554 9.33523C9.48554 9.67897 9.69291 9.82583 10.0504 9.82502C10.1611 9.82752 10.2711 9.80663 10.3731 9.7637V8.69455Z"
        fill="#5A2C78"
      />
      <path
        d="M13.5055 10.2338L13.4999 8.17622C13.4999 7.96884 13.4014 7.76712 13.0407 7.76792C12.912 7.76619 12.7839 7.78694 12.6623 7.82925L12.6688 10.2354L11.7715 10.2379V7.45969C12.0765 7.32816 12.5122 7.24182 13.0625 7.24182C14.0421 7.24182 14.3939 7.6364 14.3947 8.17138L14.3996 10.2274L13.5055 10.2338Z"
        fill="#5A2C78"
      />
      <path
        d="M16.7444 10.2277L15.8746 8.74298H15.8633V10.2277H14.9757L14.9668 5.94543H15.8544L15.8617 8.58725H15.873L16.7347 7.28571H17.6288L16.6605 8.60903L17.7902 10.2414L16.7444 10.2277Z"
        fill="#5A2C78"
      />
      <path
        d="M5.57036 13.3131L6.72746 11.538C7.10684 11.4915 7.48863 11.4672 7.87085 11.4653C9.13285 11.4653 9.84292 11.7235 10.1996 12.3005C10.3973 12.6289 10.4247 12.8 10.4271 13.6537L10.4336 16.1914C10.4336 17.0459 10.4884 17.3089 10.7563 17.7552L8.56156 17.7608V17.3662L8.13712 17.7318C7.84568 17.8679 7.52573 17.9318 7.20434 17.9182C6.0077 17.9182 5.25647 17.2791 5.25405 16.2414C5.24875 15.824 5.39171 15.4183 5.6575 15.0964C5.91975 14.7938 6.28689 14.5824 6.99616 14.3169L8.54461 13.7634C8.54461 12.8831 8.34369 12.6337 7.63442 12.6337C7.1414 12.637 6.4523 12.8589 5.57036 13.3131ZM8.5777 14.9544H7.47385C7.17206 15.2045 7.07039 15.428 7.07039 15.7839C7.07039 16.2963 7.40123 16.6239 7.91361 16.6223C8.03741 16.6263 8.16071 16.6048 8.2759 16.5593C8.39109 16.5138 8.49572 16.4451 8.58334 16.3576L8.5777 14.9544Z"
        fill="#5A2C78"
      />
      <path
        d="M17.4678 17.7447C17.099 17.4696 16.8876 17.2202 16.5584 16.6691L15.2875 14.5986L17.2241 11.6195L15.1092 11.6252C15.1377 11.7011 15.1553 11.7807 15.1617 11.8616C15.1617 11.9802 15.0963 12.1246 14.9785 12.3223L14.4346 13.2172L13.8924 12.2981C13.731 12.0488 13.6818 11.9043 13.681 11.7728C13.679 11.7234 13.6878 11.6741 13.7068 11.6284L11.1166 11.6348C11.5249 11.9495 11.6008 12.0544 12.0139 12.6975L13.2598 14.6656L12.0293 16.5755C11.6258 17.1944 11.4644 17.3921 11.0723 17.7609L13.2299 17.7552L14.1982 16.2673L14.6775 17.0548C14.8389 17.3308 14.9018 17.4881 14.9026 17.593C14.9046 17.6471 14.9003 17.7013 14.8897 17.7544L17.4678 17.7447Z"
        fill="#5A2C78"
      />
      <path
        d="M20.2439 17.8933C18.2727 17.8981 17.1131 16.7838 17.1075 14.8391C17.097 12.8412 18.4114 11.4445 20.3044 11.438C21.9868 11.4332 22.8704 12.4434 22.8752 14.3889C22.8752 14.5341 22.8752 14.7447 22.864 15.0344L19.1143 15.0449C19.2886 16.043 19.8284 16.5151 20.7886 16.5126C21.3897 16.5126 21.953 16.3513 22.8139 15.9341L21.6544 17.7149C21.1954 17.8425 20.7203 17.9026 20.2439 17.8933ZM19.1022 13.7425L20.9822 13.7377C20.9822 13.0115 20.6377 12.5951 20.0858 12.5959C19.5339 12.5967 19.195 13.0074 19.1005 13.7433L19.1022 13.7425Z"
        fill="#5A2C78"
      />
      <path
        d="M23.7444 21.3684L23.7226 13.1428C23.7226 12.3012 23.667 12.0519 23.416 11.6057L25.5947 11.6V12.1649L26.2144 11.6371C26.5815 11.4653 26.7526 11.4257 27.0681 11.4249C28.435 11.4249 29.4081 12.6022 29.4154 14.2588C29.421 16.3366 28.1098 17.7204 26.1506 17.7285H25.6116V18.4958L24.8596 19.6505L23.7444 21.3684ZM25.6116 16.4511C25.8113 16.5356 26.0258 16.5798 26.2426 16.5811C27.0576 16.5811 27.4901 15.9202 27.4869 14.6445C27.4869 13.4478 27.1012 12.8176 26.3644 12.8201C26.1143 12.8201 25.8916 12.9008 25.6027 13.0718L25.6116 16.4511Z"
        fill="#5A2C78"
      />
      <path
        d="M33.0476 12.7765L33.8343 11.5661H32.3351L32.3278 8.79199L29.7207 12.7983H30.459L30.4671 15.7838C30.4671 16.5988 30.5623 16.9538 30.8786 17.2944C31.1949 17.6615 31.6952 17.8318 32.3262 17.8293C32.6674 17.835 33.007 17.7804 33.3292 17.668L34.3402 16.1147L34.3459 16.1034C33.7811 16.4471 33.493 16.5528 33.1355 16.5536C32.6102 16.5536 32.3464 16.2793 32.3577 15.7007L32.3496 12.7821L33.0476 12.7765Z"
        fill="#5A2C78"
      />
    </svg>
  </React.Fragment>
);
export default IconBankaxept;
