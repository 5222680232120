import * as React from "react";
import { AppHeaderButtonText, AppHeaderNavigationItem } from "./styles";
import IconUser from "../../assets/partials/icon_user";
import { FormattedMessage } from "react-intl";

const MyPageButton: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <a href={`https://my.${window.ENVIRONMENT.domain}`} className={AppHeaderNavigationItem}>
        <div className={AppHeaderButtonText} style={{ paddingRight: "5px" }}>
          <IconUser />
        </div>
        <span className={AppHeaderButtonText}><FormattedMessage id="containers.Header.mypage" defaultMessage="Min side" />  </span>
      </a>
    </React.Fragment>
  );
};

export default MyPageButton;
