import * as React from "react";
import BrandIconSmall from "../../assets/partials/icon_hoopla_small";

const HooplaLogo: React.FunctionComponent<{ footer?: boolean }> = (props) => {
    return (
        <React.Fragment>
            <a aria-label={"Hoopla-logo"} href={`https://www.${window.ENVIRONMENT.domain}`}>
                <BrandIconSmall />
            </a>
        </React.Fragment>
    );
};

export default HooplaLogo;
