import { style } from "typestyle";

export const Body = style({
  fontFamily: "Inter",
  fontSize: "18px",
  lineHeight: 1.3
});

export const H1 = style({
  fontWeight: 900,
  fontSize: "46px"
});

export const H2 = style({
  fontWeight: 900
});
